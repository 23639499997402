import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import AppRoutes from 'appRoutes';
import { COLOR, theme } from 'constants/theme';
import { APP_KEY, TOAST } from 'constants/index';
import GlobalStyles from 'globalStyles';
import LayoutContextProvider from 'layout/layoutContext';
import { FR_APP_KEY } from 'configs';

const App = () => {
  const muiTheme = createTheme({
    palette: {
      [COLOR.PRIMARY]: {
        main: theme.colors.green.main
      },
      [COLOR.INFO]: {
        main: theme.colors.darkcyan.main
      },
      [COLOR.WARNING]: {
        main: theme.colors.orange.main
      },
      [COLOR.SUCCESS]: {
        main: theme.colors.green.main
      },
      [COLOR.SECONDARY]: {
        main: theme.colors.gray.main
      },
      [COLOR.TERTIARY]: {
        main: theme.colors.blue.rbc,
        contrastText: theme.colors.white.text
      },
      [COLOR.QUATERNARY]: {
        main: theme.colors.yellow.rbc,
        contrastText: theme.colors.blue.rbc
      },
      [COLOR.TRANSPARENT]: {
        main: theme.colors.transparent,
        contrastText: theme.colors.transparent
      },
      [COLOR.BLACK]: {
        main: theme.colors.black.main,
        contrastText: theme.colors.white.text
      },
      [COLOR.OCEAN]: {
        main: theme.colors.white.main,
        contrastText: theme.colors.blue.text
      }
    }
  });
  useEffect(() => {
    const title = document.getElementById('title');
    if (title) {
      title.textContent = FR_APP_KEY;
    }
    const favicon = document.getElementById('favicon');
    if (favicon && FR_APP_KEY === APP_KEY.RAY_ZR) {
      const href = favicon.attributes['data-href'].value;
      favicon.href = `${href}f-favicon.png`;
    }
  }, []);
  return (
    <ThemeProvider theme={muiTheme}>
      <GlobalStyles />
      <SnackbarProvider maxSnack={TOAST.MAX_ALERT_NOTI} anchorOrigin={TOAST.ANCHOR}>
        <LayoutContextProvider>
          <AppRoutes />
        </LayoutContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
