import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IMG_LOCAL } from 'constants/index';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';
import { ABOUT_FUNRAYZR } from 'constants/globalContent';
import { Col, Container, Row } from 'reactstrap';
import useStyles from './styles';

const About = () => {
  const classes = useStyles();

  const aboutFunRayZrView = useMemo(() => {
    return (
      <>
        <Container className="funhero">
          <Row>
            <Col md="6" className="valignmiddle">
              <div>
                <h2>
                  {ABOUT_FUNRAYZR.aboutFunRayZR}
                </h2>
                <ul className="green-arrow-ul">
                  <li>Small business owners</li>
                  <li>Supporting our community</li>
                  <li>Providing real value</li>
                </ul>
                <Link
                  to={SHOP_VENDOR_URL.SHOP_VENDOR.URL}
                  className="button-style primary"
                >
                  Start Shopping
                </Link>
              </div>
            </Col>
            <Col md="6">
              <img className="w-100" src={IMG_LOCAL.FUNRAYZR_ABOUT_BG_1} loading="lazy" style={{ padding: '2.62rem' }} />
              <img src={IMG_LOCAL.DOTS_3_BLUE} style={{ position: 'absolute', bottom: '0', left: '0' }} />
              <img src={IMG_LOCAL.CIRCLE_3_YELLOW} style={{ position: 'absolute', top: '0', right: '0' }} />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md="6">
              <em className={classes.greenem}>We Took Action</em>
              <h3>Helping our London community</h3>
              <h4>With an easy way to raise money and support small businesses.</h4>
              <p>Many small businesses were hit hard during the pandemic and frankly are still suffering.</p>

              <h4>We wanted to make a difference</h4>
              <p>So we came up with a simple idea to sell gift cards and give a percentage to charity.</p>
              <ol>
                <li>Businesses get more customers</li>
                <li>Charities get an easy way to raise money</li>
                <li>People get more than a tax receipt</li>
              </ol>
              <p>{ABOUT_FUNRAYZR.funRayZRHelpsLocal1}</p>
            </Col>
            <Col md="6">
              <img className="w-100" src={IMG_LOCAL.RAYZR_ABOUT_BG_1} loading="lazy" />
            </Col>
          </Row>
          <Row className="minvheight">
            <Col md="6">
              <em className={classes.greenem}>What people want to know</em>
              <h2>Frequently Asked Questions</h2>
              <h3>{ABOUT_FUNRAYZR.funRayZRHelpsLocal2}</h3>
            </Col>
            <Col md="6">
              <details>
                <summary>Do businesses have to wait for their money?</summary>
                {ABOUT_FUNRAYZR.atFunRayZR_textInfoAbout}
              </details>
              <details>
                <summary>What payment methods do you accept?</summary>
                Most major credit cards or your first born (just kidding).
              </details>
              <details>
                <summary>What happens if my gift card goes unclaimed?</summary>
                The business and charity get paid, so no one minds :)
              </details>
              <details>
                <summary>Do you mail me a physical gift card?</summary>
                {ABOUT_FUNRAYZR.justLike_textInfoAbout1}
              </details>
            </Col>
          </Row>
          <Row>
            <Col md="12 text-center">
              <em className={classes.greenem}>Founders & Staff</em>
              <h2>Meet the team</h2>
              <h3>We are a distributed team headquartered in London Ontario</h3>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" className="contact-card-container">
              <div className="contact-card">
                <img src={IMG_LOCAL.RAYZR_ABOUT_TEAM_1} loading="lazy" />
                <strong>Chris Doris</strong>
                <em>CEO & Founder</em>
                <p>Owner of Olive R Twist Restaurant</p>
                <div>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_FACEBOOK} />
                  </a>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_X} />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" className="contact-card-container">
              <div className="contact-card">
                <img src={IMG_LOCAL.RAYZR_ABOUT_TEAM_2} loading="lazy" />
                <strong>Bob Brown</strong>
                <em>CFO</em>
                <p>Financial expert from London</p>
                <div>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_FACEBOOK} />
                  </a>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_X} />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" className="contact-card-container">
              <div className="contact-card">
                <img src={IMG_LOCAL.RAYZR_ABOUT_TEAM_3} loading="lazy" />
                <strong>Elen Benitez</strong>
                <em>CPO</em>
                <p>The only way to do great work is to love what you do.</p>
                <div>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_FACEBOOK} />
                  </a>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_X} />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" className="contact-card-container">
              <div className="contact-card">
                <img src={IMG_LOCAL.RAYZR_ABOUT_TEAM_4} loading="lazy" />
                <strong>Kacy Wade</strong>
                <em>Customer Success</em>
                <p>There are no traffic jams along the extra mile.</p>
                <div>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_FACEBOOK} />
                  </a>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_X} />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" className="contact-card-container">
              <div className="contact-card">
                <img src={IMG_LOCAL.RAYZR_ABOUT_TEAM_5} loading="lazy" />
                <strong>Alya Levine</strong>
                <em>Backend Developer</em>
                <p>The mind is everything. What you think you become.</p>
                <div>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_FACEBOOK} />
                  </a>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_X} />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" className="contact-card-container">
              <div className="contact-card">
                <img src={IMG_LOCAL.RAYZR_ABOUT_TEAM_6} loading="lazy" />
                <strong>Rose Hernandez</strong>
                <em>IOS Developer</em>
                <p>Whether you think you can or you think you can’t, you’re right.</p>
                <div>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_FACEBOOK} />
                  </a>
                  <a href="/signIn">
                    <img className={`${classes.socialIcon}`} src={IMG_LOCAL.CONTACT_X} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  });

  const renderView = useMemo(() => {
    return aboutFunRayZrView;
  }, [aboutFunRayZrView]);

  return (
    // <div className="fr-bg-admin pb-3" id={GLOBAL_ELEMENT.top_page}>
      <Container>{renderView}</Container>
    // </div>
  );
};

export default About;
