import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { IMG_LOCAL, BUCKET, APP_KEY } from 'constants/index';

const HowItWorks = () => {

  return (
    <>
      <Container className="funhero">
        <Row className="py-4">
          <Col md="6" className="valignmiddle">
            <div>
              <em className="greenem">HOW IT WORKS FOR CONSUMERS</em>
              <h2>Gift cards with a twist</h2>
              <h3>Buy a gift card to a local businesses and they will donate 10% of your purchase to select organizations.</h3>
              <p>Shop with any of these participating businesses</p>
            </div>
          </Col>
          <Col md="6">
            <img className="w-100" src={IMG_LOCAL.VIDEO_FRAME} loading="lazy" />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md="4">
            <em className="greenem">1</em>
            <h3>Shop by business or organization.</h3>
            <p>Browse through participating businesses & charities to find ones you love.</p>
          </Col>
          <Col md="4">
            <em className="greenem">2</em>
            <h3>Buy for yourself or as a gift</h3>
            <p>Pay with your credit card. You can email the card as a gift, or use it yourself.</p>
          </Col>
          <Col md="4">
            <em className="greenem">3</em>
            <h3>Redeem for full value</h3>
            <p>Use 100% of the money just like you would cash, the business will donate 10% automatically.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">Shop with any of these participating businesses</p>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <em className="greenem">HOW IT WORKS</em>
            <h2>For organizations</h2>
            <h3>Set up a campaign for free in minutes</h3>
          </Col>
          <Col md="6">
            <img className="w-100" src={IMG_LOCAL.PLACEHOLDER_VIDEO} loading="lazy" />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <em className="greenem">1</em>
            <h3>Enter your campaign info</h3>
            <p>Tell people what you&rsquo;re raising money for.</p>
          </Col>
          <Col md="4">
            <em className="greenem">2</em>
            <h3>Local businesses will join</h3>
            <p>We let them know about new campaigns.</p>
          </Col>
          <Col md="4">
            <em className="greenem">3</em>
            <h3>Promote your campaign page</h3>
            <p>Email your memebers & share on socials.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">Help support any of these organizations</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HowItWorks;
