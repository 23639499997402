import React, { useCallback, useEffect, useState } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { URL_PARAM } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import Button from 'components/button';
import Input from 'components/input';
import { theme } from 'constants/theme';
import authService from 'services/authorization';
import useToast from 'components/toast/useToast';
import { FORGOT_PASSWORD_URL } from 'pages/forgotPassword/url';
import imageHelper from 'utils/image';
import { FR_APP_KEY } from 'configs';
import urlHelper from 'utils/url';
import useLoading from 'components/loading/useLoading';
import { gR } from 'constants/helperMessage';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { LANDING_URL } from 'pages/landing/url';
import { SIGNIN_URL } from './url';

const SignIn = ({ history, location }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailParam, setEmailParam] = useState('');
  const { toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();
  const [redirect, setRedirect] = useState();

  const isWebshop = location.pathname === SIGNIN_URL.SIGNIN.URL;

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);
      showLoading();
      const { email, password } = values;
      const resp = await authService.signIn(email, password);
      setIsDisabled(false);
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        const urlLocal = JSON.parse(localStorage.getItem('redirectUrl'));
        if (urlLocal) {
          localStorage.removeItem('redirectUrl');
          setRedirect(urlLocal);
          return;
        }

        let redirectUrl = urlHelper.getSearchParam(URL_PARAM.REDIRECT_URL);
        if (urlHelper.isMatchUrl(ADMIN_PORTAL_URL.ADMIN_PORTAL.URL, redirectUrl)) {
          const isMan = authService.isManager();
          if (isMan) {
            const tenantType = authService.getTenantType();
            redirectUrl = urlHelper.getTenantUrl(tenantType);
          }
        }

        history.push(redirectUrl || LANDING_URL.LANDING.URL);
      }
    },
    [hideLoading, history, showLoading, toastError]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(ERROR_MESSAGE.EMAIL.VALID).required(gR('Email')),
      password: Yup.string().required(gR('Password'))
    }),
    onSubmit
  });

  useEffect(() => {
    const e = urlHelper.getSearchParam(URL_PARAM.EMAIL) || '';
    if (e !== emailParam) {
      setEmailParam(e);
      formik.setFieldValue('email', e);
    }
  }, [emailParam, formik]);

  const logoPath = imageHelper.getContentLogo();

  return (
    <div className={`${isWebshop ? 'fr-background' : 'fr-container fr-bg-admin'} fr-box-container`}>
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content pt-2">
            <form onSubmit={formik.handleSubmit}>
              <Row className="text-center">
                <Col xs={12} className="my-2">
                  <Input
                    className="w-75 w-xs-50 w-sm-30"
                    name="email"
                    label="Email"
                    size="small"
                    color={isWebshop ? 'primary' : 'tertiary'}
                    autoComplete="username"
                    value={formik.values.email ?? ''}
                    onChange={formik.handleChange}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Col>
                <Col xs={12} className="mt-2 mb-1">
                  <Input
                    className="w-75 w-xs-50 w-sm-30"
                    name="password"
                    label="Password"
                    type="password"
                    size="small"
                    color={isWebshop ? 'primary' : 'tertiary'}
                    autoComplete="current-password"
                    value={formik.values.password ?? ''}
                    onChange={formik.handleChange}
                    error={formik.touched.password && !!formik.errors.password}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <div className="d-inline-flex w-75 w-xs-50 w-sm-30 text-right">
                    <Button
                      className="w-100 text-gray"
                      variant="text"
                      kind="link"
                      component={Link}
                      to={FORGOT_PASSWORD_URL.FORGOT_PASSWORD.URL}
                    >
                      Forgot your password?
                    </Button>
                  </div>
                </Col>
                <Col xs={12} className="my-3">
                  <Button
                    type="submit"
                    size="large"
                    color={isWebshop ? 'primary' : 'tertiary'}
                    wider
                    bgHover={[
                      isWebshop ? theme.colors.orange.button : theme.colors.yellow.rbc,
                      theme.colors.white.text
                    ]}
                    minWidth={130}
                    disabled={isDisabled}
                  >
                    <span className="text-uppercase">Sign In</span>
                  </Button>

                  <p className="fr-register">
                    Don&rsquo;t have an account? <a href="register">Sign up</a>
                  </p>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </div>
      {redirect && <Redirect to={redirect} />}
    </div>
  );
};

export default withRouter(SignIn);
