import Contact from '.';
import { CONTACT_URL } from './url';

export const CONTACT_ROUTES = [
  {
    name: CONTACT_URL.CONTACT.NAME,
    path: CONTACT_URL.CONTACT.URL,
    component: Contact,
    isPrivate: false
  }
];
