import HowItWorks from '.';
import { HOW_IT_WORKS_URL } from './url';

export const HOW_IT_WORKS_ROUTES = [
  {
    name: HOW_IT_WORKS_URL.HOW_IT_WORKS.NAME,
    path: HOW_IT_WORKS_URL.HOW_IT_WORKS.URL,
    component: HowItWorks,
    isPrivate: false
  }
];
