import React, { useState, useEffect, useRef } from 'react';
import { Col, Container } from 'reactstrap';
import charityService from 'services/charity';
import fileService from 'services/file';
import { BUCKET, IMG_LOCAL } from 'constants/index';
import { GLOBAL_ELEMENT } from 'constants/globalElement';
import Select from 'components/select';
import { getIsFunRayZR, isMobile } from 'utils/helper';
import publicService from 'services/public';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { InputBase, Paper } from '@mui/material';
import { CharityGrid, useGrib } from './grid';

const headCells = [
  { id: 'displayName', label: 'Display Name' },
  { id: 'businessNumber', label: 'Business Number' },
  { id: 'isActive', label: 'Active' },
  { id: 'action', label: '', className: 'w-30 w-xl-25' }
];
const DELAY_DEBOUNCUE_MS = 500;
const ALL_ORGANIZATION_TYPE = 'all';

const Charity = () => {
  const { toastError } = useToast();
  const [searchTemp, setSearchTemp] = useState();
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useGrib();
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [organizationTypeId, setOrganizationTypeId] = useState(ALL_ORGANIZATION_TYPE);
  const isMobileDevice = isMobile();
  const isCancelled = useRef(false);
  const isFunRayZR = getIsFunRayZR();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchText(searchTemp ?? '');
    }, DELAY_DEBOUNCUE_MS);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTemp]);

  useEffect(() => {
    const fetchOrganizationTypes = async () => {
      const resp = await publicService.getOrganizationTypes();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }

      if (!isCancelled.current) {
        let orgTypes = [{ value: ALL_ORGANIZATION_TYPE, text: 'All Organizations' }];
        if (Array.isArray(resp)) {
          orgTypes = [...orgTypes, ...resp];
        }
        setOrganizationTypes(orgTypes);
      }
    };
    const topEl = document.getElementById(GLOBAL_ELEMENT.top_page);
    const scroll = () => {
      if (topEl) {
        setTimeout(() => {
          topEl.scrollIntoView();
        }, 500);
      }
    };
    scroll();
    fetchOrganizationTypes();

    return () => {
      isCancelled.current = true;
    };
  }, [toastError]);

  const convertDataToCells = (arr) => {
    if (!arr) return [];
    return arr.map((value) => {
      const rs = {};
      rs.title = value.displayName;
      rs.id = value.id;
      rs.image = value.logoPath
        ? `${fileService._baseUrl}/${BUCKET.CHARITY}/${value.logoPath}`
        : isFunRayZR ? IMG_LOCAL.FUNRAYZR_LOGO : IMG_LOCAL.RAYZR_LOGO;
      rs.introduction = value.introduction;
      return rs;
    });
  };

  const handleChangeOrganization = (e) => {
    setOrganizationTypeId(e.target.value);
    setSearchText('');
  };

  const dataConvert = convertDataToCells(data.items);

  return (
    <div className="fr-background">
      <Container fluid className="x-container p-0" id={GLOBAL_ELEMENT.top_page}>
        <div className="p-4 justify-content-center">
          <Col xs="12" className="row justify-content-between align-items-center">
            <div className="col-xs-12 col-md-5" />

            <h2 className="text-center col-md-12" style={{ fontSize: '1.83rem' }}>Charities & Associations</h2>

            <div className="col-xs-12 col-md-5 d-sm-flex justify-content-end">
              <div className="text-right pr-xs-5 mt-4 mt-md-0 mr-md-4 d-flex d-sm-block justify-content-center">
                <Select
                  color="transparent"
                  haveSearch
                  width={!isMobileDevice && 215}
                  size="small"
                  className="bg-white rounded c-custom-select"
                  name="role"
                  value={organizationTypeId}
                  data={organizationTypes}
                  labelInput=""
                  onChange={handleChangeOrganization}
                />
              </div>

              <div className="text-center text-sm-right pr-xs-5 mt-4 mt-md-0 d-flex d-sm-block justify-content-center">
                <div className="c-input w-100 flex-1 py-1 rounded">
                  <InputBase
                    placeholder="Charity Name"
                    fullWidth
                    onChange={(e) => {
                      setSearchTemp(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </div>
        <div className="fr-page-container bg-transparent pb-3" style={{ minHeight: '700px' }}>
          <CharityGrid
            headCells={headCells}
            rowCells={dataConvert}
            totalCount={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 1}
            searchText={searchText}
            organizationType={organizationTypeId}
            title="Charities"
            service={charityService}
            api="getCharityPublic"
            setDataFetch={setData}
          />
        </div>
      </Container>
    </div>
  );
};

export default Charity;
