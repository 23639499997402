import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';

const useStyles = makeStyles(() => ({
  heroTitle: {
    fontSize: theme.fontSize.s30,
    marginBottom: '2rem',
    fontWeight: '900',
    color: 'inherit'
  },
  heroGrey: {
    fontSize: theme.fontSize.s20,
    color: 'rgba(85,105,135,1)'
  },
  greenBubble: {
    background: 'rgba(42,209,103,1)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '48px',
    width: '48px',
    padding: '12px',
    marginTop: '40px',
    marginBottom: '20px',
    boxSizing: 'border-box'
  },
  contactInput: {
    color: 'rgba(85,105,135,1)',
    lineHeight: '1.5rem',
    '-webkit-appearance': 'none',
    padding: '.5rem .75rem',
    borderRadius: '.5rem',
    border: '1px solid rgba(213,218,225,1)',
    boxShadow: '0 1px 2px 0 rgba(85, 105, 135, 0.1)',
    '&:focus': {
      outline: '2px solid rgba(42,209,103,1)'
    }
  },
  contactTextArea: {
    height: '13rem',
    resize: 'none'
  },
  contactBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.5rem'
  },
  socialIcon: {
    marginRight: '2rem',
    '&:hover': {
      filter: 'brightness(0.83)'
    }
  }
}));

export default useStyles;
