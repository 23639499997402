export const SHOP_VENDOR_URL = {
  SHOP_VENDOR: {
    URL: '/shop-vendors/list?cate=all',
    NAME: 'Shop Vendors'
  },
  SHOP_VENDOR_LIST: {
    URL: '/shop-vendors/list',
    URL_SEARCH: '/shop-vendors/list?cate=:id',
    NAME: 'Shop Vendors'
  },
  DETAIL: {
    URL: '/shop-vendors/detail/:id',
    NAME: 'Vendor Detail'
  },
  FUNRAYZR_CARD: {
    URL: '/shop-vendors/funrayzr-card/:vendorId/:campaignId',
    NAME: 'Funrayzr cards detail'
  },
  RAYZR_CARD: {
    URL: '/shop-vendors/rayzr-card/:vendorId/:amount',
    NAME: 'Rayzr cards detail'
  }
};
