import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { IMG_LOCAL, BUCKET, APP_KEY } from 'constants/index';
import { Link } from 'react-router-dom';
import { ABOUT_URL } from 'pages/about/url';
import useStyles from './styles';

const Contact = () => {

  const {
    contactInput,
    contactBlock,
    contactTextArea,
    socialIcon,
    heroTitle,
    heroGrey,
    greenBubble
  } = useStyles();

  return (
      <Container>
          <Row className="py-4">
            <Col md="6">
              <em className="greenem">CONTACT FUNRAYZR</em>
              <h2>Let&rsquo;s connect</h2>
              <h3>Got questions? We probably have answers. Here are a few ways you can reach us.</h3>
            </Col>
            <Col md="6" className="text-right">
              <Link
                to={ABOUT_URL.ABOUT.URL}
                className="button-style"
              >
                About Us
              </Link>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Container>
                <Row>
                  <Col md="6">
                    <img className={`${greenBubble}`} src={IMG_LOCAL.CONTACT_MAIL} />
                    <h3 className={`${heroTitle}`}>Email</h3>
                    <h4 className={`${heroGrey}`}>info@funrayzr.com</h4>
                  </Col>
                  <Col md="6">
                    <img className={`${greenBubble}`} src={IMG_LOCAL.CONTACT_PHONE} />
                    <h3 className={`${heroTitle}`}>Phone</h3>
                    <h4 className={`${heroGrey}`}>(226) 376-4911</h4>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <img className={`${greenBubble}`} src={IMG_LOCAL.CONTACT_OFFICE} />
                    <h3 className={`${heroTitle}`}>Office</h3>
                    <h4 className={`${heroGrey}`}>2899 Cromarty Drive RR#1 Putnam, Ontario, N0L 2B0</h4>
                  </Col>
                  <Col md="6">
                    <img className={`${greenBubble}`} src={IMG_LOCAL.CONTACT_SOCIALS} />
                    <h3 className={`${heroTitle}`}>Socials</h3>
                    <a href="/contact">
                      <img className={`${socialIcon}`} src={IMG_LOCAL.CONTACT_FACEBOOK} />
                    </a>
                    <a href="/contact">
                      <img className={`${socialIcon}`} src={IMG_LOCAL.CONTACT_X} />
                    </a>
                    <a href="/contact">
                      <img className={`${socialIcon}`} src={IMG_LOCAL.CONTACT_INSTAGRAM} />
                    </a>
                    <a href="/contact">
                      <img className={`${socialIcon}`} src={IMG_LOCAL.CONTACT_LINKEDIN} />
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg="6">
              <form className="contact-form valignmiddle">
                <div className={`${contactBlock}`}>
                  <label htmlFor="email-input">Email</label>
                  <input className={`${contactInput}`} id="email-input" type="email" placeholder="email@email.com" />
                </div>
                <div className={`${contactBlock}`}>
                  <label htmlFor="email-message">Message</label>
                  <textarea className={`${contactInput} ${contactTextArea}`} id="email-message" placeholder="Your Message&hellip;" />
                </div>
                <button type="submit" className="button-style primary">Send</button>
              </form>
            </Col>
          </Row>
      </Container>
  );
};

export default Contact;
