import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { CircularProgress, Paper } from '@mui/material';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import publicService from 'services/public';
import { getHasNextPage, isURL } from 'utils/helper';
import { Col, Container, Row } from 'reactstrap';
import fileService from 'services/file';
import { BUCKET, DATE_FORMAT, TAB_VALUE } from 'constants/index';
import { theme } from 'constants/theme';
import Button from 'components/button';

const PAGE_SIZE = 4;
const DELAY_IN_MS = 300;

const useStyles = makeStyles(() => ({
  colCharity: {
    maxWidth: '30%',
    '@media (max-width: 576px)': {
      maxWidth: '100%'
    }
  },
  textUnderline: {
    textDecoration: 'underline',
    '& a': {
      color: `${theme.colors.white.text}!important`,
      textDecoration: 'underline'
    }
  }
}));

const CampaignsTab = ({ campaignSelected, onSetValue, onSetCampaignSelected }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);

  const match = useRouteMatch();
  const { colCharity, textUnderline } = useStyles();
  const { toastError } = useToast();

  useEffect(() => {
    const fetchId = async () => {
      setLoading(true);
      const respCharities = await publicService.getCampaigns({
        charityId: match.params.id,
        pageSize: PAGE_SIZE,
        pageIndex: 0
      });

      if (respCharities?.errorCode) {
        toastError(respCharities?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      }
      const { items, totalPages, pageIndex } = respCharities;
      setHasNextPage(getHasNextPage(totalPages, pageIndex));
      setData(items);
      setLoading(false);
    };
    fetchId();
  }, [match.params.id, toastError]);

  const onLoadMore = async () => {
    setLoading(true);
    const newPageIndex = page + 1;
    const respCharities = await publicService.getCampaigns({
      charityId: match.params.id,
      pageSize: PAGE_SIZE,
      pageIndex: newPageIndex
    });

    if (respCharities?.errorCode) {
      toastError(respCharities?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      return;
    }
    const { items, totalPages, pageIndex } = respCharities;

    setPage(newPageIndex);
    setHasNextPage(getHasNextPage(totalPages, pageIndex));
    setData([...data, ...items]);
    setLoading(false);
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    delayInMs: DELAY_IN_MS
  });

  const handleSelect = (campaign) => () => {
    if (!campaign) return;
    onSetCampaignSelected(campaign);
    onSetValue(TAB_VALUE.SUPPORTING_VENDORS);
  };

  useEffect(() => {
    if (!campaignSelected?.id) return;
    return onSetCampaignSelected({});
  });

  return (
    <div className="mt-4">
      {data.length <= 0 && (
        <div
          className="w-100 d-flex justify-content-center align-items-center font-weight-bolder pt-5"
          style={{ minHeight: '200px' }}
        >
          No Data
        </div>
      )}
      <ul className="row justify-content-between py-0 px-3 list-style-none">
        {!!data.length &&
          data?.map((campaign) => (
            <li key={campaign.id} xs={12} md={4} className="mb-4 p-0 col-xs-12 col-md-12">
              <Paper elevation={3} className="white-bg-o2 p-2 mb-2 c-vendor-campaign">
                <Container fluid>
                  <Row className="my-2">
                    <Col
                      xs={12}
                      xl={2}
                      className="d-flex justify-content-center align-items-center d-sm-block"
                    >
                      <Paper
                        elevation={5}
                        className="c-vendor-campaign-image d-flex justify-content-center align-items-center"
                      >
                        {campaign?.logoPath ? (
                          <img
                            src={
                              fileService._baseUrl + '/' + BUCKET.CAMPAIGN + '/' + campaign.logoPath
                            }
                            alt={campaign?.displayName}
                            loading="lazy"
                            className="img-fluid mw-95"
                          />
                        ) : (
                          <div className="d-flex justify-content-between align-items-center text-center c-vendor-campaign-name">
                            {campaign?.name}
                          </div>
                        )}
                      </Paper>
                    </Col>
                    <Col
                      xs={12}
                      xl={10}
                      className="mt-sm-1 my-2 mb-sm-0 d-flex justify-content-center flex-column d-sm-block"
                    >
                      <div className="d-flex flex-column-reverse flex-xl-row justify-content-between w-100">
                        <div className="text-break">
                          <h2 className="h3 text-break">{campaign?.name}</h2>
                          <div>
                            {moment(campaign?.startDate).format(DATE_FORMAT)}&nbsp;-&nbsp;
                            {moment(campaign?.endDate).format(DATE_FORMAT)}
                          </div>
                          <div className={textUnderline}>
                            {isURL(campaign?.campaignUrl) ? (
                              <a href={campaign?.campaignUrl} target="blank">
                                {campaign?.campaignUrl}
                              </a>
                            ) : (
                              <>{campaign?.campaignUrl}</>
                            )}
                          </div>
                        </div>
                        <Button
                          variant="text"
                          className="my-3 my-xl-0 min-w-120"
                          minWidth={120}
                          onClick={handleSelect(campaign)}
                        >
                          Select
                        </Button>
                      </div>
                      <div className="mt-1 c-vendor-desc">{campaign?.description}</div>
                    </Col>
                  </Row>
                </Container>
              </Paper>
            </li>
          ))}
        <li xs={12} md={4} className={`${colCharity} mb-4 p-0 col-xs-12 col-md-4`} />
        {(loading || hasNextPage) && (
          <li className="col-12 text-center mt-5" ref={sentryRef}>
            <CircularProgress />
          </li>
        )}
      </ul>
    </div>
  );
};

export default CampaignsTab;
