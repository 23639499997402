import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { CircularProgress, InputBase, Paper, Tab, Tabs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Col, Container, Row } from 'reactstrap';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import publicService from 'services/public';
import fileService from 'services/file';
import { BUCKET, DATE_FORMAT, IMG_LOCAL } from 'constants/index';
import { getHasNextPage, isMobile, isURL } from 'utils/helper';
import Button from 'components/button';
import Select from 'components/select';
import { theme } from 'constants/theme';
import { SHOP_VENDOR_URL } from '../../url';

const PAGE_SIZE = 4;
const DELAY_IN_MS = 300;
const ALL_CATEGORY = 'all';

const useStyles = makeStyles(() => ({
  colCharity: {
    maxWidth: '30%',
    '@media (max-width: 576px)': {
      maxWidth: '100%'
    }
  },
  tabs: {
    color: 'white',
    '& .MuiTabs-scroller': {
      borderBottom: '1px solid',
      marginBottom: '2rem !important'
    },
    '& button': {
      color: `${theme.colors.white.text} !important`,
      fontWeight: 700,
      fontSize: '1.2rem'
    },
    '& .MuiTabs-indicator': {
      height: 3,
      background: theme.colors.white.background
    }
  },
  textUnderline: {
    textDecoration: 'underline',
    '& a': {
      color: `${theme.colors.white.text}!important`,
      textDecoration: 'underline'
    }
  }
}));

const CharityTab = ({ vendorId }) => {
  const [searchTemp, setSearchTemp] = useState();
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [charityId, setCharityId] = useState(ALL_CATEGORY);
  const [value] = useState(0);

  const history = useHistory();
  const { colCharity, tabs, textUnderline } = useStyles();
  const { toastError } = useToast();
  const isMobileDevice = isMobile();
  const defaultOptionCharity = useRef({ value: ALL_CATEGORY, text: 'All Charities' }).current;

  const handleChangeCharity = (e) => {
    setCharityId(e.target.value);
    setSearchTemp('');
    setPage(0);
  };

  const handleFormatCharityData = useCallback((values) => {
    return values.items.map(({ id, displayName }) => ({ value: id, text: displayName }));
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(0);
      setSearchText(searchTemp ?? '');
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchTemp]);

  useEffect(() => {
    const fetchId = async () => {
      setLoading(true);
      const respCharities = await publicService.getCharitiesCampaigns({
        charityId: charityId !== ALL_CATEGORY ? charityId : '',
        pageSize: PAGE_SIZE,
        pageIndex: 0,
        searchText
      });

      if (respCharities?.errorCode) {
        toastError(respCharities?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }

      const { items, totalPages, pageIndex } = respCharities;
      setData(items);
      setHasNextPage(getHasNextPage(totalPages, pageIndex));
      setLoading(false);
    };

    fetchId();
  }, [charityId, searchText, toastError]);

  const onLoadMore = async () => {
    setLoading(true);
    const newPageIndex = page + 1;
    const respCharities = await publicService.getCharitiesCampaigns({
      charityId: charityId !== ALL_CATEGORY ? charityId : '',
      pageSize: PAGE_SIZE,
      pageIndex: newPageIndex,
      searchText
    });

    if (respCharities?.errorCode) {
      toastError(respCharities?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      return;
    }
    const { items, totalPages, pageIndex } = respCharities;
    setPage(newPageIndex);
    setHasNextPage(getHasNextPage(totalPages, pageIndex));
    setData([...data, ...items]);
    setLoading(false);
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    delayInMs: DELAY_IN_MS
  });

  const handleClickViewMore = (campaignId) => () => {
    history.push(
      SHOP_VENDOR_URL.FUNRAYZR_CARD.URL.replace(':vendorId', vendorId).replace(
        ':campaignId',
        campaignId
      )
    );
  };

  return (
    <div className="mb-5">
      <div className="d-flex justify-content-center justify-content-md-end align-items-center">
        <div className="col-xs-12 col-md-5 d-sm-flex justify-content-end">
          <div className="text-right pr-xs-5 mt-4 mt-md-0 mr-md-4 d-flex d-sm-block justify-content-center">
            <Select
              async
              service={publicService}
              api="getCharities"
              formatData={handleFormatCharityData}
              defaultOption={defaultOptionCharity}
              haveSearch
              color="transparent"
              width={!isMobileDevice && 220}
              size="small"
              className="bg-white rounded c-vendor-list-category c-custom-select w-100 min-w-220"
              name="role"
              value={charityId}
              labelInput={!charityId ? 'Choose Charity' : ''}
              onChange={handleChangeCharity}
            />
          </div>
          <div className="text-sm-right pr-xs-5 mt-4 mt-md-0 d-flex d-sm-block justify-content-center">
            <Paper className="c-input w-100 flex-1 py-1 rounded">
              <InputBase
                value={searchTemp}
                placeholder="Campaign/ Charity Name"
                className="c-vendor-detail-search"
                onChange={(e) => {
                  setSearchTemp(e.target.value);
                }}
              />
            </Paper>
          </div>
        </div>
      </div>
      <Container fluid>
        <Tabs value={value} aria-label="basic tabs example" className={tabs}>
          <Tab label="CAMPAIGNS" />
        </Tabs>
        <ul className="row justify-content-between py-0 px-3 list-style-none">
          {data.length ? (
            data?.map((campaign) => (
              <li key={campaign.id} xs={12} md={4} className="mb-4 p-0 col-xs-12 col-md-12">
                <Paper elevation={3} className="white-bg-o2 p-2 mb-2 c-vendor-campaign">
                  <Container fluid>
                    <Row className="my-2">
                      <Col
                        xs={12}
                        xl={4}
                        className="d-flex justify-content-center align-items-center d-sm-block"
                      >
                        <Paper elevation={5} className="c-vendor-campaign-image px-2 px-xl-4">
                          <img
                            src={
                              campaign?.charityLogoPath
                                ? fileService._baseUrl +
                                  '/' +
                                  BUCKET.CHARITY +
                                  '/' +
                                  campaign.charityLogoPath
                                : IMG_LOCAL.DEFAULT_VENDOR
                            }
                            alt={campaign?.displayName}
                            loading="lazy"
                            className="img-fluid"
                          />
                          <AddIcon color="primary" fontSize="large" />
                          {campaign?.logoPath ? (
                            <img
                              src={
                                fileService._baseUrl +
                                '/' +
                                BUCKET.CAMPAIGN +
                                '/' +
                                campaign.logoPath
                              }
                              alt={campaign?.displayName}
                              loading="lazy"
                              className="img-fluid"
                            />
                          ) : (
                            <div className="d-flex justify-content-between align-items-center text-center c-vendor-campaign-name">
                              {campaign?.name}
                            </div>
                          )}
                        </Paper>
                      </Col>
                      <Col
                        xs={12}
                        xl={8}
                        className="mt-sm-1 my-2 mb-sm-0 d-flex justify-content-center flex-column d-sm-block"
                      >
                        <div className="d-flex flex-column-reverse flex-xl-row justify-content-between w-100">
                          <div className="text-break">
                            <h2 className="h3 text-break">{campaign?.name}</h2>
                            <div>
                              {moment(campaign?.startDate).format(DATE_FORMAT)}&nbsp;-&nbsp;
                              {moment(campaign?.endDate).format(DATE_FORMAT)}
                            </div>
                            <div className={textUnderline}>
                              {isURL(campaign?.campaignUrl) ? (
                                <a href={campaign?.campaignUrl} target="blank">
                                  {campaign?.campaignUrl}
                                </a>
                              ) : (
                                <>{campaign?.campaignUrl}</>
                              )}
                            </div>
                          </div>
                          <Button
                            variant="text"
                            className="my-3 my-xl-0 min-w-120"
                            minWidth={120}
                            onClick={handleClickViewMore(campaign.id)}
                          >
                            Select
                          </Button>
                        </div>
                        <div className="mt-1 c-vendor-desc">{campaign?.description}</div>
                      </Col>
                    </Row>
                  </Container>
                </Paper>
              </li>
            ))
          ) : (
            <div
              className="w-100 d-flex justify-content-center align-items-center font-weight-bolder pt-5 text-center"
              style={{ minHeight: '200px' }}
            >
              No Data
            </div>
          )}
          <li xs={12} md={4} className={`${colCharity} mb-4 p-0 col-xs-12 col-md-4`} />
          {(loading || hasNextPage) && (
            <li className="col-12 text-center mt-5" ref={sentryRef}>
              <CircularProgress />
            </li>
          )}
        </ul>
      </Container>
    </div>
  );
};

export default CharityTab;
