import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CHARITY_ROUTES } from 'pages/charity/route';
import { SHOP_VENDOR_ROUTES } from 'pages/shopVendor/route';
import { REGISTER_ROUTES } from 'pages/register/route';
import { SIGNIN_ROUTES } from 'pages/signIn/route';
import { SCAN_VENDOR_TRANSACTIONS_ROUTES } from 'pages/scanVendorTransactions/route';
import { SIGNEDOUT_ROUTES } from 'pages/signedOut/route';
import { FORGOT_PASSWORD_ROUTES } from 'pages/forgotPassword/route';
import AuthorizeRoute from 'authorizeRoute';
import { RESET_PASSWORD_ROUTES } from 'pages/resetPassword/route';
import { ADDTIONAL_ADMIN_PORTAL_ROUTES, ADMIN_PORTAL_ROUTES } from 'pages/adminPortal/route';
import { AdminPortalLayout, Layout } from 'layout';
import { ABOUT_ROUTES } from 'pages/about/route';
import { PRIVACY_POLICY_ROUTES } from 'pages/privacyPolicy/route';
import { EULA_PAGE_ROUTES } from 'pages/eula/route';
import { TERM_OF_USE_ROUTES } from 'pages/termOfUse/route';
import { SHIPPING_ONLY_ROUTES } from 'pages/shippingOnly/route';
import { REFUND_ONLY_ROUTES } from 'pages/refundOnly/route';

import { CART_ROUTES } from 'pages/cart/route';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { VENDOR_MANAGE_ROUTES } from 'pages/adminPortal/vendorManageRoute';
import authService from 'services/authorization';
import { ADMIN_PORTAL_BASIC_ROUTES } from 'pages/adminPortal/basicRoute';
import { CHARITY_MANAGE_ROUTES } from 'pages/adminPortal/charityManageRoute';
import { CHECKOUT_ROUTES } from 'pages/checkout/route';
import { TRANSACTION_ROUTES } from 'pages/transaction/route';
import { LANDING_ROUTES } from 'pages/landing/route';

import { HOW_IT_WORKS_ROUTES } from 'pages/howItWorks/route';
import { CONTACT_ROUTES } from 'pages/contact/route';
import { CHARITY_DRIVES_ROUTES } from 'pages/charityDrives/route';
import { LOCAL_BUSINESS_ROUTES } from 'pages/localBusiness/route';
import { NON_PROFIT_ROUTES } from 'pages/nonProfit/route';

const APP_ROUTES = [
  ...LANDING_ROUTES,
  ...CHARITY_DRIVES_ROUTES,
  ...CONTACT_ROUTES,
  ...HOW_IT_WORKS_ROUTES,
  ...LOCAL_BUSINESS_ROUTES,
  ...NON_PROFIT_ROUTES,
  ...ABOUT_ROUTES,
  ...PRIVACY_POLICY_ROUTES,
  ...CHARITY_ROUTES,
  ...SHOP_VENDOR_ROUTES,
  ...SIGNIN_ROUTES,
  ...REGISTER_ROUTES,
  ...FORGOT_PASSWORD_ROUTES,
  ...RESET_PASSWORD_ROUTES,
  ...SIGNEDOUT_ROUTES,
  ...CART_ROUTES,
  ...CHECKOUT_ROUTES,
  ...TRANSACTION_ROUTES,
  ...EULA_PAGE_ROUTES,
  ...REFUND_ONLY_ROUTES,
  ...SHIPPING_ONLY_ROUTES,
  ...TERM_OF_USE_ROUTES
];

const RedirectToHome = () => <Redirect to="/" />;

const AppRoutes = () => {
  const [isSuperAdministrator, setIsSuperAdministrator] = useState(false);
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isVendorManager, setIsVendorManager] = useState(false);
  const [isCharityManager, setIsCharityManager] = useState(false);
  const [isVendorEmployee, setIsVendorEmployee] = useState(null);
  const { listen, location } = useHistory();

  useEffect(() => {
    const setState = () => {
      const isSuperAdmin = authService.isSuperAdministrator();
      const isAdmin = authService.isSuperAdministrator() || authService.isAdministrator();
      const isVenManager = authService.isManager() && authService.isVendor();
      const isChaManager = authService.isManager() && authService.isCharity();
      const isVenEmployee = authService.isEmployee() && authService.isVendor();
      setIsSuperAdministrator(isSuperAdmin);
      setIsAdministrator(isAdmin);
      setIsVendorManager(isVenManager);
      setIsCharityManager(isChaManager);
      setIsVendorEmployee(isVenEmployee);
    };
    setState();
    listen(() => {
      setState();
    });
    return () => {
      setState({});
    };
  }, [listen]);

  const generateRoutes = (routes = []) => {
    return routes.map((route) => {
      if (route.isPrivate) {
        return (
          <AuthorizeRoute exact path={route.path} key={uuidv4()}>
            <route.component />
          </AuthorizeRoute>
        );
      }
      return (
        <Route exact path={route.path} key={uuidv4()}>
          <route.component />
        </Route>
      );
    });
  };

  const renderRoutes = useMemo(() => {
    let routes = ADMIN_PORTAL_BASIC_ROUTES;
    if (isAdministrator) {
      routes = ADMIN_PORTAL_ROUTES;
      if (isSuperAdministrator) {
        routes = [...ADMIN_PORTAL_ROUTES, ...ADDTIONAL_ADMIN_PORTAL_ROUTES];
      }
    } else if (isVendorManager) {
      routes = VENDOR_MANAGE_ROUTES;
    } else if (isCharityManager) {
      routes = CHARITY_MANAGE_ROUTES;
    }

    let webshopRoutes = APP_ROUTES;
    if (isVendorEmployee) {
      webshopRoutes = [...APP_ROUTES, ...SCAN_VENDOR_TRANSACTIONS_ROUTES];
    }

    const webshopPaths = webshopRoutes.map((value) => value.path);

    if (isVendorEmployee !== null && !webshopPaths.includes(location.pathname)) {
      webshopRoutes = [...webshopRoutes, { path: '*', component: RedirectToHome }];
    }

    return (
      <Switch>
        <Route
          path={[`${ADMIN_PORTAL_URL.ADMIN_PORTAL.URL}/:path?/:path?/:path?/:path?/:path?`]}
          exact
        >
          <AdminPortalLayout>
            <Switch>{generateRoutes(routes)}</Switch>
          </AdminPortalLayout>
        </Route>

        <Route>
          <Layout>
            <Switch>{generateRoutes(webshopRoutes)}</Switch>
          </Layout>
        </Route>
      </Switch>
    );
  }, [
    isAdministrator,
    isVendorManager,
    isCharityManager,
    isVendorEmployee,
    location.pathname,
    isSuperAdministrator
  ]);

  return renderRoutes;
};

export default AppRoutes;
