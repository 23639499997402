import NonProfit from '.';
import { NON_PROFIT_URL } from './url';

export const NON_PROFIT_ROUTES = [
  {
    name: NON_PROFIT_URL.NON_PROFIT.NAME,
    path: NON_PROFIT_URL.NON_PROFIT.URL,
    component: NonProfit,
    isPrivate: false
  }
];
