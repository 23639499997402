export const REGISTER_URL = {
  REGISTER: {
    URL: '/register',
    NAME: 'Sign Up'
  },
  REGISTER_SUCCESS: {
    URL: '/register-success',
    NAME: 'Register Success'
  },
  REGISTER_CONFIRMATION_FAILED: {
    URL: '/register-confirmation-failed',
    NAME: 'Email Account Verification'
  },
  REGISTER_CONFIRMATION: {
    URL: '/register-confirmation',
    NAME: 'Register Confirmation'
  },
  REGISTER_VENDOR: {
    URL: '/register/vendor',
    NAME: 'Vendor Registration'
  },
  REGISTER_VENDOR_SUCCESS: {
    URL: '/register/vendor-success',
    NAME: 'Register Vendor Success'
  },
  REGISTER_CHARITY: {
    URL: '/register/charity',
    NAME: 'Community Organization Registration'
  },
  REGISTER_CHARITY_SUCCESS: {
    URL: '/register/charity-success',
    NAME: 'Register Charity Success'
  }
};
