import { theme } from 'constants/theme';
import QuicksandLight from 'assets/fonts/Quicksand-Light.ttf';
import QuicksandRegular from 'assets/fonts/Quicksand-Regular.ttf';
import QuicksandMedium from 'assets/fonts/Quicksand-Medium.ttf';
import QuicksandBold from 'assets/fonts/Quicksand-Bold.ttf';
import QuicksandSemiBold from 'assets/fonts/Quicksand-SemiBold.ttf';
import TitanOneRegular from 'assets/fonts/TitanOne-Regular.ttf';

import { IMG_LOCAL } from 'constants/index';

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Font Face */
  @font-face {
    font-family: ${theme.fontFamily.quicksandLight};
    src: local('@'), url(${QuicksandLight}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: ${theme.fontFamily.quicksandRegular};
    src: local('@'), url(${QuicksandRegular}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: ${theme.fontFamily.quicksandMedium};
    src: local('@'), url(${QuicksandMedium}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: ${theme.fontFamily.quicksandBold};
    src: local('@'), url(${QuicksandBold}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: ${theme.fontFamily.quicksandSemiBold};
    src: local('@'), url(${QuicksandSemiBold}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: ${theme.fontFamily.titanOneRegular};
    src: local('@'), url(${TitanOneRegular}) format('truetype');
    font-weight: 400;
  }

  .quicksand  {
    font-family: ${theme.fontFamily.quicksandRegular} !important;
  }

  .titanOne * {
    font-family: ${theme.fontFamily.titanOneRegular} !important;
  }

  .greenem2 {
    background: rgba(234,250,240,1);
    font-size: 0.76rem;
    font-weight: 500;
    font-style: initial;
    text-transform: uppercase;
    padding: .38em .62em;
    border-radius: 1em;
    color: rgba(42,209,103,1);
  }



  body {
    color: rgba(42,51,66,1);
    background: repeating-linear-gradient(transparent, transparent 23px, #f9fafa 23px, #f9fafa 24px), repeating-linear-gradient(90deg, transparent, transparent 23px, #f9fafa 23px, #f9fafa 24px);
  }

  .funhero {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 83vh;
    height: 100vh;
    background: white;
  }

  .minvheight {
    min-height: 62vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .valignmiddle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .boop {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .greenem {
    background: rgba(42,209,103,1);
    color: white;
    font-size: 0.76rem;
    font-weight: 400;
    font-style: initial;
    text-transform: uppercase;
    padding: .38em .62em;
    border-radius: 1em;
  }

  h2 {
    font-weight: 900;
    font-size: 2.62rem;
  }

  h3 {
    font-size: 1.38rem;
    color: rgba(85,105,135,1);
  }


  details summary {
    font-size: 1.24rem;
    font-weight: bold;
    line-height: 64px;
  }


  .contact-form {
    background: rgba(247,248,249,1);
    padding: 2.62rem 2rem;
    border-radius: .38rem;
  }

  button {
    border: none;
  }

  .carousel {
    // min-height: 240px;

    margin-bottom: 5rem;
    margin-top: 2rem;
  }

  .red-arrow-ul {
    margin: 0;
    padding: 0;
  }

  .red-arrow-ul li {
    position: relative;
    list-style: none;
    padding: 0 0 0 calc(24px + .62rem);
    margin: 1.62rem 0 0 0;
    font-size: 1rem;
  }

  .red-arrow-ul li:not(:last-child) {
    margin-right: 1.62rem;
  }

  .red-arrow-ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 24px;
    width: 24px;
    background: url(${IMG_LOCAL.CHECKBOX_RED});
  }

  .green-arrow-ul li {
    position: relative;
    list-style: none;
    padding: 0 0 0 1rem;
    margin: 1.62rem 0;
    color: rgba(85,105,135,1);
    font-size: 1.25rem;
  }

  .green-arrow-ul li:before {
    content: '';
    position: absolute;
    left: -24px;
    top: 0;
    height: 24px;
    width: 24px;
    background: url(${IMG_LOCAL.CHECKBOX_GREEN});
  }

  .carousel-link {
    height: 100%;
    border-radius: .875rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    // height: 240px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(229,231,235,1);
    color: inherit;
    font-weight: 500;
  }

  .logo-container {
    flex: 1;
    overflow: hidden;
  }

  .logo-container img {
    height: 96px;
    width: 96px;
    object-fit: contain;
    margin: 2rem 0 2rem 0;
    display: block;
  }

  .carousel-link > div {
    margin-bottom: 1rem;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  body {
    font-family: Poppins, ui-sans-serif, system-ui,
      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }



  .button-style {
    margin: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.15);
    border-radius: .38rem;
    padding: 1rem 1.62rem;
    background: white;
    color: inherit;
    font-size: 1.24rem;
    color: rgba(51,63,81,1);
  }

  .button-style:first-of-type {
    margin-left: 0;
  }

  .button-style:hover {
    background: rgba(238,240,243,1);
    text-decoration: none;
    color: inherit;
  }

  .button-style.primary:hover {
    background: rgba(38,188,94,1);
  }

  .button-style.primary {
    background: rgba(42,209,103,1);
    color: white;
    box-shadow: none;
  }

  .primary-nav {
    background: rgba(42,209,103,1);
    color: white !important;
    border-radius: .38rem;
    padding: .38rem 1rem !important;
  }

  .footer-paragraph {
    color: rgba(85,105,135,1)
  }

  .footer-address-link {
    color: rgba(136,150,171,1);
  }

  .footer-copyright {
    font-size: .875rem;
    font-weight: 300;
    color: rgba(77,95,122,1);
  }

  .text-decoration-underline {
    color: rgba(136,150,171,1) !important;
  }

  .footer-links {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer-links li {
    line-height: 2.62rem;
  }

  .footer-links a {
    color: rgba(85,105,135,1);
    text-decoration: none;
  }

  .footer-links a:hover {
    color: rgba(42,51,66,1);
  }

  .footer .logo {
    border: 2px solid red;
  }

  .footer-address-rows {
    padding: 0;
  }

  .contact-card-container {
    display: flex;
    padding: 10px;
  }

  .contact-card {
    flex: 1;
    background: rgba(247,248,249,1);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border-radius: .38rem;
    box-sizing: border-box;
    padding: 1.62rem;
  }

  .contact-card strong {
    font-size: 1.62rem;
    margin: 1rem 0;
  }

  .contact-card em {
    color: rgba(42,209,103,1);
    font-style: normal;
    margin: 0;
    font-size: 1rem;
  }

  .contact-card > img:first-of-type {
    display: block;
    height: 96px;
    width: 96px;
  }

  .contact-card p {
    margin: 1.62em 0;
    color: rgba(85,105,135,1);
  }

  .contact-card a {
    color: rgba(85,105,135,1);
  }

  /* Html */
  html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }

  /* Body */
  body {
    min-height: 100vh;
  }

  /* Top Menu */
  header {
    &.fr-header {
      height: ${theme.menu.height};
    }
  }
  .fr-navbar {
    height: ${theme.menu.height};
    padding: 4px 1rem;
    background: ${theme.menu.background};
    background: repeating-linear-gradient(transparent, transparent 23px, #f9fafa 23px, #f9fafa 24px), repeating-linear-gradient(90deg, transparent, transparent 23px, #f9fafa 23px, #f9fafa 24px);
    @media (max-width: 768px) {
      padding: 2px 0;
    }

    .fr-nav-left {
      flex-grow: 1;
      max-width: 150px;
    }

    .fr-nav-right {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 240px;
    }
    .grecaptcha-badge{
      display: none;
  }
    .fr-nav-left {
      @media (max-width: 768px) {
        padding-left: 1rem;
      }
    }

    .fr-navbar-brand {
      cursor: pointer;
      img {
        height: 50px;
      }
    }

    .fr-nav-right {
      display: none;
      &.fr-admin {
        max-width: 250px;
        .profile-info {
          display: flex;
          align-items: center;
          .avatar {
            height: 45px;
            width: 45px;
            border-radius: 50%;
            background: ${theme.colors.blue.rbc};
            color: ${theme.colors.white.text};
            font-family: ${theme.fontFamily.quicksandMedium};
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: ${theme.fontSize.s20};
          }
          .profile {
            display: flex;
            flex-direction: column;
            padding-left: 5px;
            .name, .role {
              font-family: ${theme.fontFamily.quicksandRegular};
              color: ${theme.colors.black.title};
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 100px;
            }
            .name {
              font-size: ${theme.fontSize.s13};
            }
            .role {
              font-size: ${theme.fontSize.s8};
            }
          }
        }
      }
      @media (min-width: 768px) {
        display: flex;
      }
    }

    .fr-navbar-toggler {
      @media (max-width: 768px) {
        padding-right: 1rem;
      }
      button {
        &:focus {
          outline: none;
        }
      }
    }
    .fr-content {
      flex-grow: 1;
      background: ${theme.colors.white.background};
    }
    .fr-nav {
      flex-grow: 1;
      justify-content: center;
      &.fr-admin-nav {
        flex-grow: 1;
        margin-left: auto;
        margin-right: auto;
      }
      .fr-admin-logout {
        @media (min-width: 768px) {
          position: absolute;
          right: 15px;
        }
      }
    }
    .fr-nav-item {
      align-self: center;
      &.title {
        font-size: ${theme.fontSize.s24};
        font-family: ${theme.fontFamily.titanOneRegular};
        color: ${theme.colors.blue.rbc};
        max-width: 200px;
      }
      .fr-nav-link {
        font-family: ${theme.fontFamily.quicksandMedium};
        font-size: ${theme.menu.fontSize};
        color: rgba(85,105,135,1);
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        padding: 0 2rem;
        box-sizing: border-box;
        &.gray {
          color: ${theme.colors.gray.menu};
          &.active,
          &:hover,
          &:focus {
            color: ${theme.colors.gray.menu};
          }
        }
        &.orange {
          color: ${theme.colors.orange.menu};
          &.active,
          &:hover,
          &:focus {
            color: ${theme.colors.orange.menu};
          }
        }
        &.violet {
          color: ${theme.colors.violet.menu};
          &.active,
          &:hover,
          &:focus {
            color: ${theme.colors.violet.menu};
          }
        }
        &.active,
        &:hover,
        &:focus {
          color: rgba(42,51,66,1);
        }
      }
    }

    .fr-nav-right-cart-span {
      position: absolute;
      padding: 1px 8px;
      background: ${theme.colors.violet.background};
      color: ${theme.colors.white.text};
      top: 4px;
      right: -7px;
      font-size: 1rem;
      line-height: 1rem;
      border-radius: 10px;
      border: 1px solid snow
    }

  }




  /* Body content */
  .fr-body {
    display: flex;
  }

  /* Sidebar menu */
  .fr-sidebar {
    background: ${theme.colors.blue.rbc};
    width: 220px;
    min-height: calc(100vh - ${theme.menu.height});
    height: calc(100vh - ${theme.menu.height});
    padding: 45px 0;
    position: relative;
    transition: 0.5s;
    &.sidebar-collapse {
      width: 60px;
      .fr-nav-item {
        .fr-nav-sub-item {
          .fr-nav-link {
            padding-left: 25px;
          }
        }
      }
    }
    .fr-sidebar-icon {
      position: absolute;
      top: 0;
      right: -10px;
      background: ${theme.colors.violet.sidebar};
      border-radius: 3px;
      cursor: pointer;
      width: 47px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      & button {
        background: none;
        border: none;
        outline: none;
        transition: 0.5s;
        &.close {
          transform: rotate(180deg);
        }
      }
      & svg {
        color: ${theme.colors.white.text};
      }
    }
    .fr-sidebar-content {
      height: 100%;
      overflow-y: auto;
    }
    .fr-nav-item {
      margin: 10px 0;
      position: relative;
      max-width: 100%;
      &.active {
        background: ${theme.colors.yellow.rbc};
        a {
        color: ${theme.colors.blue.rbc} !important;
        }
      }
      .fr-nav-sub-item {
        list-style: none;
        padding-left: 0;
        .fr-nav-link {
          font-size: ${theme.fontSize.s14};
          padding-left: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fr-nav-item:last-child {
          margin-bottom: 0;
        }
      }
      .fr-nav-ico {
        position: absolute;
        top: 0;
        right: 5px;
        height: 40px;
        display: flex;
        align-items: center;
        & svg {
          color: ${theme.colors.white.text};
          cursor: pointer;
        }
      }
      .fr-nav-link {
        font-family: ${theme.fontFamily.quicksandRegular};
        font-size: ${theme.menu.fontSize};
        color: ${theme.colors.white.text};
        cursor: pointer;
        padding-right: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          background: ${theme.colors.yellow.rbc};
        }
      }
    }
  }
  .custom-mobile{
     @media (max-width: 759px) {
      padding-bottom: 15px;
    }
  }
  /* Content */
  .content {
    flex: 1;
    background-attachment: fixed;
  }

  .fr-container {
    min-height: calc(100vh - ${theme.menu.height}) !important;
    height: calc(100vh - ${theme.menu.height});
    overflow-y: auto;
  }

  .fr-container.pb-40 {
    padding-bottom: 40px;
  }

  .fr-background {
    min-height: 100%;
    &.white {
      background: ${theme.colors.white.background};
    }
  }

  .fr-bg-admin {
    background-image: linear-gradient(155deg, ${theme.colors.blue.rbc}, ${theme.colors.yellow.rbc});
    padding-bottom:100px;
  }

  .fr-box-container {
    min-height: 100%;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
    .fr-box {
      flex-grow: 1;
      display: flex;
      align-items: center;
      .close-popup{
         cursor: pointer;
         margin-top:-15px;
         border: none;
         outline: none;
         background: transparent;
      }
    }
    .fr-box-logo {
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }
    .fr-box-logo img {
      width: 350px;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    .fr-box-button {
      padding-top: 50px;
      text-align: center;
    }
  }

  .fr-page-title {
    font-size: 40px;
    text-align: center;
    color: ${theme.colors.white.title};
    font-family: TitanOne Regular;
    padding: 25px 0;
    margin-bottom: 0;
  }
  .w-380{
     @media (min-width: 770px) {
      width: 380px;
    }
  }
  .fr-page-container {
    background: ${theme.colors.white.background};
    border-radius: 15px;
    &.fr-content {
      padding: 40px;
    }
    &.tab-background {
      padding: 20px 30px;
      border-radius: 5px;
    }
  }

  .fr-content-title {
    font-size: ${theme.fontSize.s40};
    color: ${theme.colors.black.title};
    font-family: ${theme.fontFamily.quicksandLight};
    text-align: center;
    @media (max-width: 414px) {
      font-size: ${theme.fontSize.s38};
    }
    @media (max-width: 375px) {
      font-size: ${theme.fontSize.s36};
    }
  }

  .fr-content-sub-title-0 {
    font-size: 20px;
    color: ${theme.colors.black.title};
    font-family: ${theme.fontFamily.quicksandLight};
  }

  .fr-content-sub-title {
    font-size: 18px;
    color: ${theme.colors.black.title};
    font-family: ${theme.fontFamily.quicksandLight};
    a{
       text-decoration: underline;
    }
  }
  .fr-register {
    font-size: 20px;
    color: ${theme.colors.black.title};
    font-family: ${theme.fontFamily.quicksandMedium};
    margin-top: 20px;
    a{
       text-decoration: underline;
       color: ${theme.colors.black.text}
    }
  }


  .fr-small,
  .fr-small > a {
    font-size: 16px;
    color: ${theme.colors.gray.text};
    opacity: 100%;
  }

  .fr-small > a {
    text-decoration: underline;
  }

  .flex-1 {
    flex: 1
  }

  .flex-unset {
    flex: unset !important;
  }
  .flex{
    display: flex;
  }
  .justify-content-end{
    justify-content: flex-end;
  }
  /* Color */
  .darkcyan-title {
    color: ${theme.colors.darkcyan.title};
  }

  .darkcyan-text {
    color: ${theme.colors.darkcyan.text};
  }

  .darkcyan-bg-o7 {
    background: ${theme.colors.darkcyan.o7};
  }

  .white-bg {
    background: ${theme.colors.white};
  }

  .white-bg-o2 {
    background: ${theme.colors.white.o2} !important;
  }

  .violet-title {
    color: ${theme.colors.violet.title};
  }

  .violet-text {
    color: ${theme.colors.violet.text};
  }

  .violet-text-hover:hover {
    color: ${theme.colors.violet.text};
  }

  .violet-bg-o7 {
    background: ${theme.colors.violet.o7};
  }

  .orange-title {
    color: ${theme.colors.orange.title};
  }

  .orange-text {
    color: ${theme.colors.orange.text};
  }

  .gray-text {
    color: ${theme.colors.gray.text}
  }

  .white-title {
    color: ${theme.colors.white.title}
  }

  .black-title {
    color: ${theme.colors.black.title};
  }

  .black-text {
    color: ${theme.colors.black.text};
  }

  .orange-bg {
    background: ${theme.colors.orange.background};
  }

  .orange-bg-o7 {
    background: ${theme.colors.orange.o7};
  }

  .bg-primary {
    background: ${theme.colors.violet.background} !important;
  }
  .bg-tertiary {
    background: ${theme.colors.blue.rbc} !important;

  }
  .bg-info {
    background: ${theme.colors.darkcyan.background} !important;
  }
  .bg-infor-about{
    background: ${theme.colors.yellow.background} !important;
  }
  .bg-danger {
    background: ${theme.colors.orange.background} !important;
  }
  .bg-footer {
    background: ${theme.colors.black.background} !important;
  }
  .text-primary {
    color: ${theme.colors.violet.main}!important;
  }

  .text-info {
    color: ${theme.colors.darkcyan.main}!important;
  }

  .text-warning {
    color: ${theme.colors.orange.main}!important
  }

  .text-white-hover:hover {
    color: ${theme.colors.white.text}
  }

  .text-gray {
    color: ${theme.colors.gray.text} !important
  }

  .text-tertiary {
    color: ${theme.colors.blue.rbc} !important
  }

  .text-black{
    color: ${theme.colors.black.text} !important;
    font-family: ${theme.fontFamily.quicksandBold};
  }
  .pointer {
    cursor: pointer;
  }

  /*Font-weight*/

  .font-bold{
    font-weight:600;
  }
    /* Toast */
  .text-12 {
    font-size: 1.2rem;
  }

  .text-1 {
    font-size: 1rem;
  }

  .list-style-none {
    list-style-type: none;
  }

  .mw-85 {
    max-width: 85% !important;
  }

  .mw-95 {
    max-width: 95% !important;
  }

    /* Toast */
  .SnackbarItem-variantSuccess {
    background: ${theme.colors.white.background} !important;
    color: ${theme.colors.green.toast} !important
  }

  .SnackbarItem-variantError {
    background: ${theme.colors.white.background} !important;
    color: ${theme.colors.red.toast} !important
  }

  .SnackbarItem-variantInfo {
    background: ${theme.colors.white.background} !important;
    color: ${theme.colors.darkcyan.toast} !important
  }

  .SnackbarItem-variantWarning {
    background: ${theme.colors.white.background} !important;
    color: ${theme.colors.orange.toast} !important
  }

  .SnackbarContainer-top {
    top: 80px !important;
  }

  /* Container */
  .container-100h {
    min-height: calc(100vh - 64px);
  }

  @media (min-width: 1200px) and (max-width: 1366px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 960px;
    }
  }

  .rounded-9 {
    border-radius: 9px !important;
  }
  .rounded-20 {
    border-radius: 20px !important;
  }

  /*  Button  */
  .MuiButton-label {
    font-family: ${theme.fontFamily.quicksandMedium};
  }

  .Mui-checked {
    margin-left: 12px !important;
  }

  .MuiFormControlLabel-label {
    color: ${theme.colors.blue.rbc};
  }

  .MuiFormHelperText-root {
    margin: 4px 5px 0px 5px !important
  }
  .mlp-2{
    margin-left: -20px !important;
    margin-right: 5px;
  }
  .min-fix{
    min-height:200px;
  }
  /* Width */
  .w-30 {
    width: 30% !important
  }
  .w-5{
    max-width: 20px !important

  }
  .w-37 {
    width: 37% !important
  }

  .min-w-50 {
    min-width: 50%;
  }

  .min-w-120 {
    min-width: 120px !important;
  }

  .min-w-220 {
    min-width: 220px !important;
  }

  .min-w-300 {
    min-width: 300px !important;
  }

  a[type="button"] {
    -webkit-appearance: button;
    -webkit-appearance: attachment;
  }

  .c-about-funray {
    margin-top: -10px;
    position: relative;
    z-index: 999;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }

  .c-input {
    display: inline-block;
    border-radius: 0;
    padding: 2px 10px;
    max-width: 300px;
    @media(max-width: 414px) {
      max-width: 100%;
    }
  }


  /* Checkout */
  .c-card-form {
    // background: ${theme.colors.white.background};
    padding: 30px 30px;

    .c-card-title, .c-field-title {
      font-family: ${theme.fontFamily.quicksandMedium};
      color: ${theme.colors.black.title}
    }
    .c-card-title {
      font-size: ${theme.fontSize.s24};
    }
    .c-field-title {
      font-size: ${theme.fontSize.s14};
      margin-bottom: 5px;
    }
    .c-card-name {
      outline: none;
      border: 1px solid #ced4da;
      box-shadow: none;
    }
    .c-card-number {
      background-repeat: no-repeat;
      background-origin: content-box;
      background-position: calc(100% + 40px) center;
      background-repeat: no-repeat;
      background-size: contain;

    }
    .form-control {
      /* padding: 9px 50px 9px 15px; */
      border-radius: 0;
    }
    .c-field-error {
      margin-left: 5px;
    }
    .back-url {
      font-size: ${theme.fontSize.s13};
      color: ${theme.colors.gray.text};
      text-decoration: none;
    }

  }

  .c-title {
    text-align: center;
    // color: ${theme.colors.white.title};
    // font-family: ${theme.fontFamily.titanOneRegular};
    font-weight: 900;
    font-size: 2rem;
    white-space: nowrap;
    @media (max-width: 414px) {
      font-size: 1.6rem;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .c-image-upload-text {
     background: ${theme.colors.blue.rbc};
     font-size: 12px;
     top: 50%;
     left: 50%;
     margin-top: -16.5px;
     margin-left: -26.1px;
     opacity: 0;
  }

  .c-image-upload:hover .c-image-upload-text {
    opacity: .9;
    transition: all .5s ease
  }

  .c-cart-empty {
    min-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .c-cart-gift {
    max-width: fit-content;
    padding: 5px;
    border: 1px solid ${theme.colors.violet.main};
  }

  .c-cart-btn-remove {
    margin-left: -14px !important
  }

  /* Vendor */
  .c-vendor-card {
    border-radius: 18px;
    padding: 10px 15px;
    background: ${theme.colors.white.background};
    .c-vendor-content {
      padding: 0 15px;
    }
    .c-vendor-title,
    .c-vendor-text {
      text-align: center;
      font-family: ${theme.fontFamily.quicksandLight};
      color: ${theme.colors.gray.title};
    }
    .c-vendor-title {
      font-size: ${theme.fontSize.s20};
    }
    .c-vendor-text {
      height: 30px;
      font-size: ${theme.fontSize.s13};
      margin-bottom: 0;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .c-vendor-image {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 120px;
      }
    }
  }

  .c-vendor-category {
    border-radius: 8px;

    .border-white {
      border-bottom: 1px solid ${theme.colors.white.background};
      margin: 12px -8px 8px -8px
    }

    .c-vendor-desc {
      font-weight: 200;
      font-size: 14px;
    }

    .MuiAutocomplete-root {
      .MuiFormControl-root {
      .MuiInputLabel-root {
        display: none!important
      }
    }
    }


    .c-vendor-category-image {
      height: 130px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${theme.colors.white.background};
      border-radius: 8px;

      &.block {
        height: 120px;
        max-width: 100%;
        padding: 5px;

        img {
          max-width: 100%;
        }
      }

      img {
        max-width: 124px;
        max-height: 114px;
        height: auto;
        border-radius: 8px;
      }
    }
  }

  .c-vendor-campaign {
    border-radius: 8px;

    .border-white {
      border-bottom: 1px solid ${theme.colors.white.background};
      margin: 12px -8px 24px -8px
    }

    .c-vendor-desc {
      font-weight: 200;
      font-size: 14px;
    }

    .MuiAutocomplete-root {
      .MuiFormControl-root {
      .MuiInputLabel-root {
        display: none!important
      }
    }
    }

    .c-vendor-campaign-name {
      max-width: 124px;
      overflow-wrap: anywhere;
    }

    .c-vendor-campaign-image {
      height: 150px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${theme.colors.white.background};
      border-radius: 8px;

      img {
        max-width: 35%;
        max-height: 126px;
        height: auto;
        border-radius: 8px;
      }
    }

    button {
      font-size: 1rem!important;
      max-height: 40px !important;
      background: ${theme.colors.white.background} !important;
      border-radius: 9px !important;
    }

    button:hover {
      color: ${theme.colors.white.text} !important;
      background: ${theme.colors.violet.background} !important;
    }
  }

  // Some cases, it compiles MUI css later
  .c-vendor-list-btn {
    font-size: 1rem !important;
    max-height: 40px !important;
    background: ${theme.colors.white.background} !important;
    border-radius: 9px !important;
  }

  .c-vendor-list-btn:hover {
    color: ${theme.colors.white.text} !important;
    background: ${theme.colors.violet.background} !important;
  }

  .c-vendor-info {
    padding: 10px 10px;
    .c-vendor-title,
    .c-vendor-text {
      font-family: ${theme.fontFamily.quicksandLight};
      color: ${theme.colors.white.title};
    }
    .c-vendor-title {
      font-size: ${theme.fontSize.s20};
    }
    .c-vendor-text {
      font-size: ${theme.fontSize.s13};
      height: 30px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .c-card-item {
    background: ${theme.colors.gray.backgroundCard} !important;
    border-radius: 8px !important;
    padding-top: 10px;
    color: ${theme.colors.white.text} !important;
    font-family: ${theme.fontFamily.quicksandRegular} !important;
  }

  .c-vendor-card-item {
    width: 396px;
    height: 236px;
    background: ${theme.colors.gray.backgroundCard} !important;
    border-radius: 8px !important;
    padding: 10px;
    color: ${theme.colors.white.text} !important;
    font-family: ${theme.fontFamily.quicksandRegular} !important;
  }

  .c-charity-campaign-image {
      height: 150px;
      width: 95%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${theme.colors.white.background};
      border-radius: 8px;

      img {
        max-width: 95%;
        max-height: 126px;
        height: auto;
        border-radius: 8px;
      }
  }

  .c-charity-card-item {
    max-width: 95%;
    max-height: 226px;
    background: ${theme.colors.gray.backgroundCard} !important;
    border-radius: 8px !important;
    padding: 10px;
    color: ${theme.colors.white.text} !important;
    font-family: ${theme.fontFamily.quicksandRegular} !important;
    display: flex;
    align-items: center;
  }

  .c-charity-cart-amount {
    background: ${theme.colors.violet.background};
    color: #fff;
    border-radius: 2px;
    width: fit-content;
  }

  .c-vendor-logo {
    min-height: 104px;

    img {
      max-height: 124px;
    }
  }


  .c-vendor-amount {
    min-height: 80px;
  }

  .c-vendor-detail-search {
    background: ${theme.colors.white.background};
    min-width: 200px;
  }


  .c-vendor-popular-paper {
    border-radius: 9px !important;

    h3 {
      text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
      @media (max-width: 414px) {
          font-size: 2rem;
      }
    }

    .img-fluid {
      border-radius: 9px;
      width: 100%;
      height: auto;
      min-height: 100%;
    }

    button {
      max-width: 150px;
      min-height: 90px;
      border-radius: 9px;
      padding: 7px 50px 7px 50px !important;

      span {
        text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
      }
    }
  }

   /* Charity */
  .c-charity-search {
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  .c-charity-card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0;
    padding-top: 56.25%;
    position: relative;

    img {
      max-height: 100px;
      padding: 10px 20px 10px 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .c-charity-pagination {
    color: white;
    display: flex;
    justify-content: end;
    margin-right: 48px;
    span {
      line-height: 32px;
    }
  }

  .manager-view-paging {
    .MuiTablePagination-selectLabel,
    .MuiInputBase-input,
    .MuiTablePagination-displayedRows,
    .MuiIconButton-root {
     color: ${theme.colors.gray.text} !important;
    }
  }

  .c-card-logo {
    min-width: 100px;
    max-width: 200px;
    object-fit: contain;
  }

  .c-amount-container {
    margin-right: -41px
  }

  .c-shop-cart-action {
    .MuiFormControl-root {
      max-width: 78px;
    }

    input {
      color: ${theme.colors.black.text};
      -webkit-text-fill-color: ${theme.colors.black.text} !important;
      text-align: center;
    }

    p {
      line-height: 40px;
      font-size: 18px;
      min-width: 58px;
    }
  }


  /* Pagination */
  .c-pagination {
    .MuiButtonBase-root {
      color: ${theme.colors.white.button};
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 36em) {
  .w-xs-50 {
    width: 50% !important
  }

  .SnackbarContainer-right {
      right: 30px !important
  }

  .indicator-ontent {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 10px 0;
    .indicator-item {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      margin: 0 15px;
      background: ${theme.colors.violet.background};
    }
  }

  .c-shop-vendor-sidebar {
    min-height: calc(100vh - 60px);
  }

  .c-charity-card-item {
    width: 380px;
  }

 }

 .text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
 }

 .block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  .two-lines {
    -webkit-line-clamp: 2;
  }
 }

 }
// Medium devices (tablets, 768px and up)
@media (min-width: 48em) {
  .w-sm-30 {
    width: 30% !important
  }

  .h3-md {
    font-size: 1.5rem;
  }

}


 // Large devices (medium desktops, 1366px and up)
@media (min-width: 85.376em) {
  .c-vendor-card-item {
    width: 446px;
    height: 266px;
    padding: 20px
  }

  .c-vendor-logo {
    min-height: 124px;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

}
 .w-100{
  width:100%;
 }
 .mt-30{
  margin-top:30px;
 }
 // Extra large devices (large desktops, 1600px and up)
 @media (min-width: 100em) {
    .w-xl-25 {
      width: 25% !important;
    }

    .w-xl-31 {
      width: 31% !important;
    }

    .mb-xxxl-7 {
      margin-bottom: 7rem !important;
    }
  }

  .print-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: white;
    padding: 2rem;
  }

  .download-group {
    float: left;
    p {
      color: ${theme.colors.black.text};
      font-family: ${theme.fontFamily.quicksandRegular};
      font-size: 14px;
      margin-bottom: 10px;
    }

    .MuiButton-root {
      span {
        font-size: ${theme.fontSize.s12};
        color: ${theme.colors.white.text};
      }
    }
  }
  .ml-20{
    margin-left:20px;
  }
  .br-5 {
    border-radius: 5px;
  }

  .text-decoration-underline {
    color: grey;
  }

  .hamburger-menu-logo {
    max-height: 45px;
  }

  .c-custom-select {
    .MuiOutlinedInput-notchedOutline {
      top: 0;
    }
    input {
      padding-right: 20px !important;
    }
  }

  .c-custom-select .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }

  @media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      max-width: 1536px;
    }
  }
`;

export default GlobalStyles;
