import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import publicService from 'services/public';
import { BUCKET, CART_DETAILS_TYPE, IMG_LOCAL } from 'constants/index';
import { gS } from 'constants/helperMessage';
import { FAQ_POPUP } from 'constants/globalContent';
import Button from 'components/button';
import { usePopup } from 'components/popup';

import fileService from 'services/file';
import VendorCardForm from 'pages/shopVendor/detail/component/vendorCardForm';
import cartService from 'services/cart';

import authService from 'services/authorization';
import { CART_URL } from 'pages/cart/url';
import Description from './descriptionCard';

import ProviderInformation from './providerInfo';
import { SHOP_VENDOR_URL } from '../url';
import ShopVendorCard from '../detail/component/shopVendorCard';
import FAQPopup from './faqPopup';

const ShopVendorFunrayzrCard = ({ match, history }) => {
  const isCancelled = useRef(false);
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState({});
  const [charity, setCharity] = useState({});
  const [vendor, setVendor] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, open, close] = usePopup();

  const { toastSuccess, toastError } = useToast();

  const { campaignId, vendorId } = match.params;

  useEffect(() => {
    const fetchTenantId = async () => {
      if (!isCancelled.current) {
        setLoading(true);
      }
      const resp = await publicService.getVendor(vendorId);

      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL);
      } else {
        const { displayName, address, logoPath, amount } = resp;

        if (!isCancelled.current) {
          setVendor({
            displayName,
            logoPath: logoPath
              ? `${fileService._baseUrl}/${BUCKET.VENDOR}/${logoPath}`
              : IMG_LOCAL.DEFAULT_VENDOR,
            address: address?.addressLine1,
            amount:
              amount === '0'
                ? []
                : amount
                  .replace(/ /g, '')
                  .replaceAll('Amount', '')
                  .split(',')
                  .sort((a, b) => a - b)
          });
        }
      }

      const respCampaign = await publicService.getCampaign(campaignId);

      if (respCampaign?.errorCode) {
        toastError(respCampaign?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(SHOP_VENDOR_URL.DETAIL.URL.replace(':id', vendorId));
      } else {
        const { name, description, logoPath, charityName, charityLogoPath } = respCampaign;
        if (!isCancelled.current) {
          setCampaign({
            name,
            description,
            logoPath: logoPath ? `${fileService._baseUrl}/${BUCKET.CAMPAIGN}/${logoPath}` : ''
          });
          setCharity({
            name: charityName,
            logoPath: charityLogoPath
              ? `${fileService._baseUrl}/${BUCKET.CHARITY}/${charityLogoPath}`
              : IMG_LOCAL.FUNRAYZR_LOGO
          });
          setLoading(false);
        }
      }
    };

    fetchTenantId();

    return () => {
      isCancelled.current = true;
    };
  }, [history, vendorId, toastError, campaignId]);

  const handleOnSubmit = async ({
    quantity,
    amount,
    isGift,
    to,
    recipientEmail,
    confirmEmail,
    from,
    message
  }) => {
    const price = +amount.replace('Amount', '');
    const total = quantity * price;

    const cart = {
      total,
      isLocal: true,
      cartDetails: [
        {
          campaignId,
          campaign: {
            name: campaign?.name,
            logoPath: campaign?.logoPath
          },
          charity: {
            name: charity?.name,
            logoPath: charity.logoPath
          },
          vendorId,
          vendor: {
            displayName: vendor?.displayName,
            logoPath: vendor?.logoPath,
            address: vendor?.address
          },
          cardName: vendor?.displayName,
          type: CART_DETAILS_TYPE.FUNRAY_ZR,
          quantity: +quantity,
          price,
          total,
          isGift,
          to,
          recipientEmail,
          confirmEmail,
          from,
          giftMessage: message
        }
      ]
    };
    const isLogin = !authService.isExpired();

    let products = {};
    const productsLocal = JSON.parse(localStorage.getItem('products'));
    const id = uuidv4();
    if (productsLocal?.total) {
      products = {
        isLocal: true,
        cartDetails: [
          ...productsLocal.cartDetails,
          {
            ...cart.cartDetails[0],
            logoPath: vendor.logoPath,
            address: vendor.address,
            id
          }
        ],
        total: productsLocal.total + cart.total
      };
    } else {
      products = {
        ...cart,
        cartDetails: [
          {
            ...cart.cartDetails[0],
            logoPath: vendor.logoPath,
            address: vendor.address,
            id
          }
        ]
      };
    }
    localStorage.setItem('products', JSON.stringify(products));

    if (isLogin) {
      setIsDisabled(true);
      const respone = await cartService.createCart(cart);

      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        localStorage.removeItem('products');
      }

      setIsDisabled(false);
    }

    history.push(CART_URL.CART.URL);
    toastSuccess(gS('Add to Cart'));
  };

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="fr-background pb-0 py-4 quicksand">
      <Container fluid className="x-container p-0 container rounded-9 bg-white">
        <Row className="mx-0 h-full">
          <Col xs={12} md={5} lg={4} className="p-0">
            <ProviderInformation campaign={campaign} charity={charity} vendor={vendor} />
          </Col>
          <Col xs={12} md={7} lg={8} className="px-4">
            <div className="black-text p-0 p-xs-4">
              <div className="my-4 py-4">
                <Row className="mx-0">
                  <Col className="px-0 py-sm-0">
                    <h3 className="font-weight-bolder text-center text-md-left">
                      Select your eGift card amount
                    </h3>
                  </Col>
                </Row>

                <Row className="mx-0">
                  <Col xs={12} lg={6} className="px-0 py-sm-0 py-md-5">
                    <ShopVendorCard vendor={vendor} charity={charity} campaign={campaign} />
                    <div className="d-none d-lg-block mt-0 mt-lg-3">
                      <Col xs={12} className="d-flex justify-content-center flex-wrap">
                        <Description title="FunRayZR eGift Cards" type="FunRayZR" />
                        <Button
                          color="black"
                          className="text-white-hover"
                          size="medium"
                          onClick={open}
                        >
                          {FAQ_POPUP.eGiftCardFAQ}
                        </Button>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={12} lg={6} className="px-0 py-sm-0 py-lg-5">
                    <VendorCardForm
                      vendor={vendor}
                      campaign={campaign}
                      charity={charity}
                      onSubmit={handleOnSubmit}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center flex-wrap d-block d-lg-none"
                  >
                    <Description title="FunRayZR eGift Cards" type="FunRayZR" />
                    <Button color="black" className="text-white-hover" size="medium" onClick={open}>
                      {FAQ_POPUP.eGiftCardFAQ}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <FAQPopup isOpen={isOpen} close={close} />
      </Container>
    </div>
  );
};

export default withRouter(ShopVendorFunrayzrCard);
