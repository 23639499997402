import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CircularProgress, InputBase, Paper, Tooltip } from '@mui/material';
import qs from 'qs';
import { Col, Container, Row } from 'reactstrap';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { BUCKET, IMG_LOCAL } from 'constants/index';
import { GLOBAL_ELEMENT } from 'constants/globalElement';
import vendorService from 'services/vendor';
import { getHasNextPage, getIsFunRayZR, isMobile, isURL } from 'utils/helper';
import useLoading from 'components/loading/useLoading';
import fileService from 'services/file';
import Select from 'components/select';
import useToast from 'components/toast/useToast';
import publicService from 'services/public';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import Button from 'components/button';
import { SHOP_VENDOR_URL } from './url';

const PAGE_SIZE = 2;
const DELAY_IN_MS = 300;
const ALL_CATEGORY = 'all';
const PARAM = '?cate';

const ShopVendorList = forwardRef(
  (
    {
      categoryIdCharity,
      campaignSelected,
      searchTempCharity,
      onSetCategoryDataCharity,
      onSetCategoryIdCharity
    },
    ref
  ) => {
    const [searchTemp, setSearchTemp] = useState('');
    const [searchText, setSearchText] = useState('');
    const [categoryData, setCategoryData] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [data, setData] = useState([]);
    const [categoryId, setCategoryId] = useState(ALL_CATEGORY);

    const match = useRouteMatch();
    const { push, location } = useHistory();

    const { toastError } = useToast();
    const [showLoading, hideLoading] = useLoading();
    const isMobileDevice = isMobile();
    const charityId = match.params?.id;
    const fromVendor = location.pathname.includes(SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL);
    const isFunRayZR = getIsFunRayZR();
    const isCancelled = useRef(false);

    const element = document.getElementById(GLOBAL_ELEMENT.top_page);

    useEffect(() => {
      if (element) {
        element.scrollIntoView();
      }
    }, [element]);

    useEffect(() => {
      if (!location.search) return;
      const cateParam = qs.parse(location.search);

      setCategoryId(cateParam[PARAM]);
    }, [location.search]);

    useEffect(() => {
      const fetchId = async () => {
        showLoading();
        const cateParam = qs.parse(location.search);
        let id = '';
        if (categoryIdCharity) {
          id = categoryIdCharity;
        } else if (cateParam?.[PARAM]) {
          id = cateParam[PARAM];
        }
        setCategoryId(id || ALL_CATEGORY);
        const respCharities = await vendorService.getVendorsPublic({
          categoryId: id !== ALL_CATEGORY ? id : '',
          pageSize: PAGE_SIZE,
          pageIndex: 0,
          searchText
        });

        if (respCharities?.errorCode) {
          toastError(respCharities?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
          return;
        }

        const { items, totalPages, pageIndex } = respCharities;

        setData(items);
        setHasNextPage(getHasNextPage(totalPages, pageIndex));

        hideLoading();
      };

      fetchId();

      return () => {
        isCancelled.current = true;
      };
    }, [searchText, categoryIdCharity, location.search, toastError, showLoading, hideLoading]);

    const onLoadMore = async () => {
      setLoading(true);
      const cateParam = qs.parse(location.search);
      const newPageIndex = page + 1;

      let id = '';
      if (categoryIdCharity) {
        id = categoryIdCharity;
      } else if (cateParam?.[PARAM]) {
        id = cateParam[PARAM];
      }
      const respCharities = await vendorService.getVendorsPublic({
        categoryId: id !== ALL_CATEGORY ? id : '',
        pageSize: PAGE_SIZE,
        pageIndex: newPageIndex,
        searchText
      });

      if (respCharities?.errorCode) {
        toastError(respCharities?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      const { items, totalPages, pageIndex } = respCharities;
      setPage(newPageIndex);
      setHasNextPage(getHasNextPage(totalPages, pageIndex));

      const newItems = [...data];
      items.forEach((item) => {
        const find = newItems.find((newItem) => {
          return newItem.key === item.key;
        });
        if (!find) {
          newItems.push(item);
        }
      });

      setData(newItems);
      setLoading(false);
    };

    const handleClickItem = (vendorId, chaId, vendor) => () => {
      if (!fromVendor) return;
      if (!isFunRayZR) {
        push(
          SHOP_VENDOR_URL.RAYZR_CARD.URL.replace(':vendorId', vendorId).replace(
            ':amount',
            vendor?.amount.split(', ')[0].slice(6)
          )
        );
        return;
      }
      if (!chaId) {
        push(SHOP_VENDOR_URL.DETAIL.URL.replace(':id', vendorId));
        return;
      }
      push(
        SHOP_VENDOR_URL.FUNRAYZR_CARD.URL.replace(':vendorId', vendorId).replace(
          ':charityId',
          chaId
        )
      );
    };

    const handleSelect = (vendorId) => () => {
      if (!vendorId || !campaignSelected?.id) return;

      push(
        SHOP_VENDOR_URL.FUNRAYZR_CARD.URL.replace(':vendorId', vendorId).replace(
          ':campaignId',
          campaignSelected.id
        )
      );
    };

    const [sentryRef] = useInfiniteScroll({
      loading,
      hasNextPage,
      onLoadMore,
      delayInMs: DELAY_IN_MS
    });

    const handleChangeCate = (e) => {
      setCategoryId(e.target.value);
      setSearchTemp('');
      setPage(0);
      push(SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL_SEARCH.replace(':id', e.target.value));
    };

    useImperativeHandle(ref, () => ({
      handleChangeCate(e) {
        onSetCategoryIdCharity(e.target.value);
        setSearchTemp('');
        setPage(0);
      }
    }));

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        setPage(0);
        let text = '';
        if (searchTempCharity) {
          text = searchTempCharity;
        } else if (searchTemp) {
          text = searchTemp;
        }

        setSearchText(text);
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }, [searchTemp, searchTempCharity]);

    useEffect(() => {
      const fetchCategories = async () => {
        const resp = await publicService.getCategories();
        if (resp?.errorCode) {
          toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
          return;
        }

        if (!isCancelled.current) {
          setCategoryData([{ value: ALL_CATEGORY, text: 'All Categories' }, ...resp]);
        }

        if (!onSetCategoryDataCharity) return;
        onSetCategoryDataCharity([{ value: ALL_CATEGORY, text: 'All Categories' }, ...resp]);
      };

      fetchCategories();

      return () => {
        isCancelled.current = true;
      };
    }, [toastError, onSetCategoryDataCharity]);

    const supportVendorListView = (charity) => {
      return (
        <Container fluid>
          {charity?.items?.map((item) => (
            <Row key={item?.id} className="py-3">
              <Col
                xs={12}
                md={3}
                xl={2}
                className="d-flex justify-content-center align-items-center d-sm-block"
              >
                <Paper
                  className={
                    fromVendor ? 'pointer c-vendor-category-image' : 'c-vendor-category-image'
                  }
                  onClick={handleClickItem(item.id, charityId, item)}
                >
                  <img
                    src={
                      item?.logoPath
                        ? fileService._baseUrl + '/' + BUCKET.VENDOR + '/' + item.logoPath
                        : IMG_LOCAL.DEFAULT_VENDOR
                    }
                    alt={item?.displayName}
                    loading="lazy"
                    className="img-fluid rounded"
                  />
                </Paper>
              </Col>
              <Col
                xs={12}
                md={9}
                xl={10}
                className="mt-sm-1 my-2 mb-sm-0 d-flex justify-content-center flex-column d-sm-block"
              >
                <div className="d-flex justify-content-between flex-column-reverse flex-md-row w-100">
                  <div className="text-break">
                    <h4
                      className={fromVendor ? 'pointer' : ' ' + 'h5 mb-0 font-weight-bolder'}
                      aria-hidden="true"
                      onClick={handleClickItem(item.id, charityId, item)}
                      onKeyDown={handleClickItem(item.id, charityId, item)}
                    >
                      {item?.displayName}
                    </h4>
                    <div>
                      {item?.address?.addressLine1},&nbsp;{item?.address?.city}
                      ,&nbsp;
                      {item?.address?.province},&nbsp;{item?.address?.postalCode}
                    </div>
                    <div>
                      {isURL(item?.website) ? (
                        <a
                          href={item?.website}
                          target="blank"
                          className="white-title text-white-hover"
                        >
                          <u>{item?.website}</u>
                        </a>
                      ) : (
                        <u>{item?.website}</u>
                      )}
                    </div>
                  </div>
                  {!fromVendor && campaignSelected?.id && (
                    <Button
                      variant="text"
                      className="my-3 my-xl-0 c-vendor-list-btn min-w-120"
                      minWidth={120}
                      onClick={handleSelect(item?.id)}
                    >
                      Select
                    </Button>
                  )}
                </div>
                <div className="mt-sm-1 c-vendor-desc">{item?.description}</div>
              </Col>
            </Row>
          ))}
        </Container>
      );
    };

    const vendorListView = (charity) => {
      return (
        <Container fluid>
          <Row>
            {charity?.items?.map((item) => (
              <Col md={6} xs={12} key={item?.id}>
                <Row className="py-3">
                  <Col
                    md={4}
                    xs={12}
                    className="d-flex justify-content-center align-items-center d-sm-block"
                  >
                    <Paper
                      className={
                        fromVendor
                          ? 'pointer c-vendor-category-image block'
                          : 'c-vendor-category-image block'
                      }
                      onClick={handleClickItem(item.id, charityId, item)}
                    >
                      <img
                        src={
                          item?.logoPath
                            ? fileService._baseUrl + '/' + BUCKET.VENDOR + '/' + item.logoPath
                            : IMG_LOCAL.DEFAULT_VENDOR
                        }
                        alt={item?.displayName}
                        loading="lazy"
                        className="img-fluid rounded"
                      />
                    </Paper>
                  </Col>
                  <Col md={8} xs={12}>
                    <div>
                      <h4
                        className="pointer text-center text-md-left mt-2 mt-md-0 text-ellipsis"
                        aria-hidden="true"
                        onClick={handleClickItem(item.id, charityId, item)}
                        onKeyDown={handleClickItem(item.id, charityId, item)}
                      >
                        <Tooltip title={item?.displayName} arrow>
                          <span>{item?.displayName}</span>
                        </Tooltip>
                      </h4>
                      <div className="text-ellipsis text-center text-md-left">
                        <Tooltip
                          title={
                            <span>
                              {item?.address?.addressLine1}, {item?.address?.city},{' '}
                              {item?.address?.province}, {item?.address?.postalCode}
                            </span>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <span>
                            {item?.address?.addressLine1},&nbsp;{item?.address?.city}
                            ,&nbsp;
                            {item?.address?.province},&nbsp;{item?.address?.postalCode}
                          </span>
                        </Tooltip>
                      </div>
                      <div className="text-ellipsis text-center text-md-left">
                        {isURL(item?.website) ? (
                          <a
                            href={item?.website}
                            target="blank"
                            className="white-title text-white-hover"
                          >
                            <Tooltip title={item?.website} arrow placement="bottom-start">
                              <u>{item?.website}</u>
                            </Tooltip>
                          </a>
                        ) : (
                          <Tooltip title={item?.website} arrow placement="bottom-start">
                            <u>{item?.website}</u>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Container>
      );
    };

    return (
      <div className={`${fromVendor ? 'fr-background' : null}`} id={GLOBAL_ELEMENT.top_page}>
        <Container fluid className={`x-container p-0 ${!fromVendor && 'mw-100'}`}>
          {fromVendor && (
            <div className="p-4 justify-content-center">
              <Col xs="12" className="row justify-content-between align-items-center">
                <div className="col-xs-12 col-md-5" />

                <h2 className="text-center col-md-12" style={{ fontSize: '1.83rem' }}>Shop Vendors</h2>

                <div className="col-xs-12 col-md-5 d-sm-flex justify-content-end">
                  <div className="text-right pr-xs-5 mt-4 mt-md-0 mr-md-4 d-flex d-sm-block justify-content-center">
                    <Select
                      color="transparent"
                      haveSearch
                      width={!isMobileDevice && 215}
                      size="small"
                      className="bg-white rounded c-vendor-list-category c-custom-select"
                      name="role"
                      value={categoryId}
                      data={categoryData}
                      labelInput=""
                      onChange={handleChangeCate}
                    />
                  </div>
                  <div className="text-center text-sm-right pr-xs-5 mt-4 mt-md-0 d-flex d-sm-block justify-content-center">
                    <div className="c-input w-100 flex-1 py-1 rounded">
                      <InputBase
                        value={searchTemp}
                        placeholder="Search Vendors"
                        fullWidth
                        onChange={(e) => {
                          setSearchTemp(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          )}

          <Row className={fromVendor ? 'm-0' : 'mt-5'}>
            <Col xs="12" className="p-0">
              <Container fluid={!fromVendor}>
                <ul className="row justify-content-between py-0 list-style-none px-3">
                  {data.map((vendor) => (
                    <li key={vendor.key} xs={12} md={4} className="mb-4 p-0 col-xs-12 col-md-12">
                      <div className="white-bg-o2 p-2 mb-3 c-vendor-category">
                        <div className="mb-2">
                          <h2 className="h3 ml-3 text-center text-sm-left">
                            {vendor.value}
                          </h2>
                          <div className="border-white" />
                          {!fromVendor && supportVendorListView(vendor)}
                          {fromVendor && vendorListView(vendor)}
                        </div>
                      </div>
                    </li>
                  ))}
                  <li xs={12} md={4} className="mb-4 p-0 col-xs-12 col-md-4" />
                  {(loading || hasNextPage) && (
                    <li className="col-12 text-center mt-5" ref={sentryRef}>
                      <CircularProgress />
                    </li>
                  )}
                </ul>
              </Container>
            </Col>
          </Row>

          {!data.length && (
            <div
              className="w-100 d-flex justify-content-center align-items-center font-weight-bolder pt-5 text-center"
              style={{ minHeight: '200px' }}
            >
              No Data
            </div>
          )}
        </Container>
      </div>
    );
  }
);

export default ShopVendorList;
