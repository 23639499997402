import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { REGISTER_URL } from 'pages/register/url';
import { Link } from 'react-router-dom';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';

import useStyles from './styles';

const NonProfit = () => {

  const {
    bgGreen
  } = useStyles();

  return (
      <Container>
          <Row className="py-4 text-center">
            <Col md="12">
              <em className="greenem">SELL GIFT CARDS FOR LOCAL BUSINESSES</em>
              <h2>Put the fun back in Fundraising</h2>
              <h3>Get 10% of every dollar your members spend.</h3>

              <Link
                to={SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL}
                className="button-style primary"
              >
                Request a Demo
              </Link>
              <Link
                to={REGISTER_URL.REGISTER.URL}
                className="button-style"
              >
                Sign Up
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h3>Email your members</h3>
              <p>Let them know about your campaign</p>
            </Col>
            <Col md="4">
              <h3>Support local businesses</h3>
              <p>Send them new business</p>
            </Col>
            <Col md="4">
              <h3>Sell gift cards</h3>
              <p>Fundraise on easy mode.</p>
            </Col>
          </Row>

          <Row className={bgGreen}>
            <Col md="8">
              <h2>Start raising money today</h2>
              <p>It&rsquo;s quick and easy to launch your next campaign</p>
            </Col>
            <Col md="4 text-right">
              <Link to={SHOP_VENDOR_URL.SHOP_VENDOR.URL} className="button-style">
                Get Started
              </Link>
            </Col>
          </Row>
      </Container>
  );
};

export default NonProfit;
