import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import authService from 'services/authorization';
import { URL_PARAM, IMG_LOCAL, APP_KEY, URL_PARAM_VALUE } from 'constants/index';
import urlHelper from 'utils/url';
import { FR_APP_KEY } from 'configs';
import { NavHashLink } from 'react-router-hash-link';
import { EULA_PAGE_URL } from 'pages/eula/url';
import { TERM_OF_USE_URL } from 'pages/termOfUse/url';
import { SHIPPING_ONLY_URL } from 'pages/shippingOnly/url';
import { REFUND_ONLY_URL } from 'pages/refundOnly/url';

import { PRIVACY_POLICY_URL } from 'pages/privacyPolicy/url';
import { LANDING_URL } from 'pages/landing/url';
import { usePopup } from 'components/popup';
import Button from 'components/button';

import ContactUsPopup from './contactUsPopup';

const useStyles = makeStyles(() => ({
  footer: {
    padding: '20px 0'
  },
  contactUsCus: {
    outline: 'none !important',
    background: 'transparent !important',
    border: 'none !important',
    boxShadow: 'none !important',
    '@media(max-width: 767px)': {
      width: '100%',
      textAlign: 'center'
    },
    '& span': {
      width: '100%'
    }
  },

  contactUs: {
    fontSize: theme.fontSize.s16,
    opacity: '.8',
    marginLeft: '-14px',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    fontFamily: theme.fontFamily.quicksandMedium,
    width: '100%',
    '@media(max-width: 1200px)': {
      width: '100%',
      fontSize: theme.fontSize.s12
    },
    '@media(max-width: 767px)': {
      marginLeft: '-8px',
      fontSize: theme.fontSize.s12
    }
  },
  contactUsContent: {
    opacity: '.8',
    paddingLeft: '25px',
    '& p': {
      marginBottom: 0,
      fontSize: theme.fontSize.s14,
      '&.list': {
        display: 'list-item',
        '@media(max-width: 767px)': {
          display: 'block',
          textAlign: 'center',
          paddingLeft: '0'
        }
      }
    }
  },
  adminPortal: {
    fontSize: theme.fontSize.s16,
    opacity: '.8',
    color: 'inherit'
  },
  logo: {
    maxWidth: '180px'
  },
  menuContent: {
    display: 'flex',
    marginTop: '1.5rem',
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      flexDirection: 'column'
    },
    '& a, & span': {
      opacity: '.8',
      fontSize: theme.fontSize.s15,
      marginRight: '16px',
      fontFamily: theme.fontFamily.quicksandRegular,
      '@media(max-width: 1200px)': {
        textAlign: 'center'
      }
    }
  },
  contentCenter: {
    '@media(max-width: 767px)': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  companyNamemd: {
    fontSize: theme.fontSize.s22,
    margin: '0 0 25px 25px',
    fontWeight: 'bold',
    display: 'none',
    '@media(max-width: 767px)': {
      margin: '0 0 25px 37px',
      fontSize: theme.fontSize.s18,
      display: 'block'
    }
  },
  infoFunRayZr: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media(max-width: 767px)': {
      alignItems: 'center'
    },
    '& ul': {
      margin: '0px',
      '@media(max-width: 767px)': {
        marginLeft: '-20px'
      },
      '& li': {
        listStyle: 'none',
        marginBottom: '16px',

        '& span': {
          fontSize: theme.fontSize.s16,
          marginLeft: '20px',
          opacity: '.8',
          '@media(max-width: 1200px)': {
            fontSize: theme.fontSize.s14
          }
        }
      }
    }
  },
  line: {
    width: '100%',
    height: '1px',
    background: 'rgba(238,240,243,1)',
    opacity: '1',
    margin: '0 auto',
    padding: '0 15px',
    marginTop: '40px'
  },
  logoCard: {
    display: 'flex',
    marginLeft: '10px',
    '& img': {
      maxWidth: '40px',
      marginLeft: '5px'
    },
    '& .scale': {
      maxHeight: '40px',
      marginTop: '2px',
      objectFit: 'cover'
    },
    '@media(max-width: 767px)': {
      justifyContent: 'center',
      marginLeft: '0'
    }
  }
}));

const Footer = () => {
  const [isOpenContactUs, openContactUs, closeContactUs] = usePopup();
  const isCancelled = useRef(false);
  const {
    contactUs,
    contactUsCus,
    adminPortal,
    logo,
    footer,
    menuContent,
    infoFunRayZr,
    contentCenter,
    line,
    logoCard,
    companyNamemd
  } = useStyles();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [locationPath, setLocationPath] = useState(window.location.pathname);
  const history = useHistory();

  const crtYear = new Date().getFullYear();
  useEffect(() => {
    const populateState = async () => {
      const user = authService.getDecryptedUser();
      authService.subscribeAccountStatus();
      if (isCancelled.current) return;
      if (user?.displayName) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    const subscription = authService.subscribe(() => populateState());

    populateState();

    return () => {
      isCancelled.current = true;
      if (subscription) {
        authService.unsubscribe(subscription);
      }
    };
  }, []);

  useEffect(() => {
    const showSupport = urlHelper.getSearchParam(URL_PARAM.SUPPORT) === URL_PARAM_VALUE.SHOW;
    if (showSupport) {
      openContactUs();
    }

    history.listen(async (location) => {
      setLocationPath(location.pathname);
    });
  }, [history, openContactUs]);

  const adminPortalView = useMemo(() => {
    let path = `${ADMIN_PORTAL_URL.SIGNIN.URL}?${URL_PARAM.AREA}=admin&${URL_PARAM.REDIRECT_URL}=${ADMIN_PORTAL_URL.USER.URL}`;
    if (isAuthenticated) {
      const isAdmin = authService.isSuperAdministrator() || authService.isAdministrator();
      const isManager = authService.isManager();
      if (isAdmin) {
        path = ADMIN_PORTAL_URL.USER.URL;
      } else if (isManager) {
        const tenantType = authService.getTenantType();
        path = urlHelper.getTenantUrl(tenantType);
      } else {
        path = LANDING_URL.LANDING.URL;
      }
    }

    return (
      <Link className={adminPortal} to={path}>
        {ADMIN_PORTAL_URL.ADMIN_PORTAL.NAME}
      </Link>
    );
  }, [adminPortal, isAuthenticated]);

  const footerRender = useMemo(() => {
    const isSignIn = urlHelper.isSignIn(locationPath);
    const isRegister = urlHelper.isRegister(locationPath);
    const isSignedOut = urlHelper.isSignedOut(locationPath);

    if (isSignIn || isRegister || isSignedOut) {
      return <></>;
    }

    return (
      <div className={footer}>
        <Container>
          <Row>
            <Col xs={12} xl={5}>
              <div className={`${infoFunRayZr}`}>
                <img
                  className={logo}
                  alt="logo"
                  src={
                    FR_APP_KEY === APP_KEY.FUNRAY_ZR
                      ? IMG_LOCAL.FUNRAYZR_LOGO
                      : IMG_LOCAL.RAYZR_LOGO
                  }
                />
                <p className="footer-paragraph">Shop at businesses you love and 10% goes to support local charities.</p>
                <ul className="footer-address-rows">
                  <li>
                    <p className={companyNamemd}>FunRayZR Inc.</p>
                  </li>
                  <li className="footer-address-link">
                    <img src={IMG_LOCAL.FOOTER_ICON_LOCATION} />
                    <span>2899 Cromarty Drive RR#1, Putnam ON, N0L 2B0, Canada</span>
                  </li>
                  <li className="footer-address-link">
                    <img src={IMG_LOCAL.FOOTER_ICON_PHONE} />
                    <span>(226) 376-4911 </span>
                  </li>{' '}
                  <li className="footer-address-link">
                    <img src={IMG_LOCAL.FOOTER_ICON_MAIL} />
                    <span>info@funrayzr.com</span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} xl={2}>
              <ul className="footer-links">
                <li>
                  <a href="/non-profit">Non Profit</a>
                </li>
                <li>
                  <a href="/local-business">Local Business</a>
                </li>
                <li>
                  <a href="/charity-drives">Charity Drives</a>
                </li>
              </ul>
            </Col>
            <Col xs={12} xl={2}>
              <ul className="footer-links">
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/how-it-works">How it works</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </Col>
            <Col xs={12} xl={3}>
              <Col xs={12} className={contentCenter}>
                {adminPortalView}
              </Col>
              <Col xs={12} className={logoCard}>
                <img alt="icon-card" src={IMG_LOCAL.ICON_DISCOVER} />
                <img alt="icon-card" src={IMG_LOCAL.ICON_MASTERCARD} />
                <img alt="icon-card" src={IMG_LOCAL.ICON_VISA_DEBIT} />

                {/* <img alt="icon-card" className="scale" src={IMG_LOCAL.ICON_AMERICAN_EXPRESS} /> */}
                <img alt="icon-card" className="scale" src={IMG_LOCAL.ICON_VISA} />
              </Col>
            </Col>
            <div className={line} />
          </Row>

          <div className={menuContent}>
            <NavHashLink
              className="text-decoration-underline"
              to={`${PRIVACY_POLICY_URL.PRIVACY_POLICY.URL}#headePage`}
            >
              Privacy
            </NavHashLink>
            <NavHashLink
              className="text-decoration-underline"
              to={`${TERM_OF_USE_URL.TERM_OF_USE.URL}#headePage`}
            >
              Terms of Use
            </NavHashLink>
            <NavHashLink
              className="text-decoration-underline"
              to={`${EULA_PAGE_URL.EULA_PAGE.URL}#headePage`}
            >
              EULA
            </NavHashLink>
            <NavHashLink
              className="text-decoration-underline"
              to={`${REFUND_ONLY_URL.REFUND_ONLY.URL}#headePage`}
            >
              Refund Policy
            </NavHashLink>
            <NavHashLink
              className="text-decoration-underline"
              to={`${SHIPPING_ONLY_URL.SHIPPING_ONLY.URL}#headePage`}
            >
              Shipping Policy
            </NavHashLink>
          </div>
          <div className="text-center footer-copyright">
            © {crtYear} {FR_APP_KEY}, All rights reserved.
          </div>
        </Container>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPath, isAuthenticated]);

  return (
    <>
      {footerRender}
      <ContactUsPopup isOpen={isOpenContactUs} closeContactUs={closeContactUs} />
    </>
  );
};

export default Footer;
