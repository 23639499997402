import { FR_APP_KEY } from 'configs';

const appName = FR_APP_KEY;

const REFUND_POLICY = {
    refund_policy: `${appName} Refund Policy and Product Description`,
    not_refundable_policy:
        `${appName} digital gift certificates are not refundable. They can be redeemed for goods and services only with the specified vendor.`,
    never_expire_policy:
        `${appName} digital gift certificates never expire.`,
    not_transferable_policy:
        `${appName} digital gift certificates are not transferable. They should be used by the purchaser or the person they were purchased for.`,
    delivered_policy:
        `${appName} digital gift cards are delivered by email, usually within 1 hour of purchase.`,
    redeemed_policy:
        `${appName} digital gift cards have no cash value, they can be redeemed for goods or services offered by the specific vendor.`,
    denominated_policy:
        `${appName} digital gift cards are denominated in Canadian Dollars. ${appName} gift cards can be purchased by anyone using a credit card addressed in Canada or the United States.`,
    qr_policy:
        `${appName} gift cards are redeemed by displaying your unique QR code to the vendor at time of purchase. The QR code can be displayed on your mobile device or printed out.`,
    taxable_policy:
        `There are no additional taxes or delivery charges when you purchase a ${appName} digital gift card, the card can be used to purchase taxable goods and services.`,
    close_policy: 'What if the vendor closes or goes out of business?',
    certificate_policy:
        `${appName} will provide our customers with a certificate of equal value with a ${appName} vendor of their choice.`,
    not_happy_policy:
        `What if I’m not happy with the services or goods that are purchased with ${appName} digital gift certificates?`,
    customers_policy:
        `${appName} does not assume responsibility for goods and services purchased using our digital gift certificates. Customers are encouraged to give the vendor a chance to make things right.`
};

const PRIVACY_POLICY = {
    privacyPolicyFunrayZR: 'Privacy Policy for FunRayZR Inc.',
    accessible_policy:
        `At FunRayZR, accessible from funrayzr.com, one of our main priorities is the privacy of our visitors. This Privacy Policy 
        document contains types of information that is collected and recorded by FunRayZR and how we use it.`,
    additional_policy:
        'If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.',
    appliesOnly_policy:
        `This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the 
        information that they shared and/or collect in FunRayZR. This policy is not applicable to any information collected offline or via channels other than this website.`,
    consent: 'Consent',
    website_policy:
        'By using our website, you hereby consent to our Privacy Policy and agree to its terms.',
    information_policy: 'Information we collect',
    personal_policy:
        `The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made 
        clear to you at the point we ask you to provide your personal information.`,
    contact_policy:
        `If you contact us directly, we may receive additional information about you such as your name, email address, phone number, 
        the contents of the message and/or attachments you may send us, and any other information you may choose to provide.`,
    register_policy:
        'When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.',
    weUse_policy: 'How we use your information',
    collect_policy:
        'We use the information we collect in various ways, including to:',
    provide_policy: 'Provide, operate, and maintain our website',
    improve_policy: 'Improve, personalize, and expand our website',
    understand_policy: 'Understand and analyze how you use our website',
    develop_policy:
        'Develop new products, services, features, and functionality',
    communicate_policy:
        `Communicate with you, either directly or through one of our partners, including for customer service, to provide you 
        with updates and other information relating to the website, and for marketing and promotional purposes`,
    sendEmail_policy: 'Send you emails',
    find_policy: 'Find and prevent fraud',
    logFiles_policy: 'Log Files',
    follows_policy:
        `FunRayZR follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting 
        companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol 
        (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number 
        of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for 
        analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.`,
    cookies_policy: 'Cookies and Web Beacons',
    likeAny_policy:
        `Like any other website, FunRayZR uses 'cookies'. These cookies are used to store information including visitors' preferences, 
        and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience 
        by customizing our web page content based on visitors' browser type and/or other information.`,
    advertising_policy: 'Advertising Partners Privacy Policies',
    consult_policy:
        'You may consult this list to find the Privacy Policy for each of the advertising partners of FunRayZR.',
    thirdParty_policy:
        `Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their 
        respective advertisements and links that appear on FunRayZR, which are sent directly to users' browser. They automatically 
        receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns 
        and/or to personalize the advertising content that you see on websites that you visit.`,
    note_policy:
        'Note that FunRayZR has no access to or control over these cookies that are used by third-party advertisers.',
    third_policy: 'Third Party Privacy Policies',
    advertisers_policy:
        `FunRayZR's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective 
        Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.`,
    choose_policy:
        `You can choose to disable cookies through your individual browser options. To know more detailed information about cookie 
        management with specific web browsers, it can be found at the browsers' respective websites.`,
    CCPA_policy:
        'CCPA Privacy Rights (Do Not Sell My Personal Information)',
    under_policy:
        'Under the CCPA, among other rights, California consumers have the right to:',
    request_policy:
        "Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.",
    business_policy:
        'Request that a business delete any personal data about the consumer that a business has collected.',
    consumer_policy:
        "Request that a business that sells a consumer's personal data, not sell the consumer's personal data.",
    make_policy:
        'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
    data_policy: 'GDPR Data Protection Rights',
    entitled_policy:
        'We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:',
    access_policy:
        'The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.',
    right_policy:
        `The right to rectification – You have the right to request that we correct any information you believe is inaccurate. 
        You also have the right to request that we complete the information you believe is incomplete.`,
    erasure_policy:
        'The right to erasure – You have the right to request that we erase your personal data, under certain conditions.',
    restrict_policy:
        'The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.',
    object_policy:
        'The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.',
    portability_policy:
        'The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.',
    month_policy:
        'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
    children_policy: "Children's Information",
    another_policy:
        `Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to 
        observe, participate in, and/or monitor and guide their online activity.`,
    knowingly_policy:
        `FunRayZR does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think 
        that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we 
        will do our best efforts to promptly remove such information from our records.`,
    close_policy: 'What if the vendor closes or goes out of business?',
    certificate_policy:
        'FunRayZR will provide our customers with a certificate of equal value with a FunRayZR vendor of their choice.',
    not_happy_policy:
        'What if I’m not happy with the services or goods that are purchased with FunRayZR digital gift certificates?',
    customers_policy:
        'FunRayZR does not assume responsibility for goods and services purchased using our digital gift certificates. Customers are encouraged to give the vendor a chance to make things right.'
};

const FAQ_POPUP = {
    eGiftCardFAQ: `${appName} eGift Card FAQ`,
    eGiftCardTitle: `${appName} Refund Policy and Product Description`
};

const ABOUT_RAYZR = {
    aboutRayZR: 'About RayZR',
    rayZRHelpsLocal1:
        'FunRayZR helps local consumers patronize local small businesses',
    rayZRHelpsLocal2:
        'who support the fundraising efforts of local good causes.',
    here_rayZR: 'Here’s how it works:',
    each_rayZR:
        'Each year your community supporters spend thousands of dollars on basic needs.',
    includes_rayZR:
        'This includes food, entertainment, and household supplies.',
    allows_rayZR1:
        'RayZR allows your supporters to purchase electronic gift cards to local businesses',
    allows_rayZR2:
        'and a percentage of every purchase is contributed to your group’s fundraising efforts.',
    just_rayZR:
        'Just imagine – each time your supporters buy from one products and services from a local business,',
    much_rayZR: 'much needed funds are generated for your cause.',
    card_rayZR: '100% e-card fulfillment:',
    fast_rayZR:
        'Fast delivery directly to your supporters using our RayZR platform.',
    free_rayZR:
        'Free transparent program management that includes communications tools and comprehensive',
    reporting_rayZR:
        'reporting including tracking of earnings by all your supporters to your campaign.',
    supporters_rayZR:
        'Your supporters can enlist the help of family, friends, neighbours and co-workers to use this program',
    even_rayZR:
        'so even smaller groups can harness the buying power of more than just their immediate participants.',
    contactless_rayZR: 'RayZR is a contactless fundraiser!',
    contactless1_rayZR:
        '1. We are a ‘no product sales’ fundraiser so no bringing items into your school environment,',
    handling_rayZR:
        'no handling of paper order forms, money, cheques or any kind of payments.',
    contactless2_rayZR:
        '2. Our program is run totally online including sign up, ordering and payment.',
    contactless3_rayZR:
        '3. We offer electronic gift cards for all of our retailers so there is zero wait time.'
};

const ABOUT_FUNRAYZR = {
    aboutFunRayZR: "What we're about",
    funRayZRHelpsLocal1: `We're all a little tapped out these days, so asking people to donate with nothing in return can be a push.  With FunRayZR people get to spend 100% of the money with a local
      business, they get real value, while feeling good about knowing they're supporting an organization in their area.`,
    funRayZRHelpsLocal2: `Feel free to contact us directly if you have a question that's not covered here.`,
    atFunRayZR_textInfoAbout:
        `Yes. We settle up every 2 weeks, so it's not long.`,
    justLike_textInfoAbout1:
        `No, it's all digital. No plastic will be used.`,
    justLike_textInfoAbout2:
        'and non-profit partners and supporters, FunRayZR is always available and working hard',
    justLike_textInfoAbout3:
        'to make our communities and neighbourhoods a better place to live, work and play.',
    aboutFunRayZRTitle_textBox: 'About FunRayZR for Community Organizations',
    eachYear_textBox:
        `Each year your community supporters spend thousands of dollars on basic needs. This includes food, entertainment and household 
        supplies. FunRayZR allows supports to purchase some of their essential goods and service in advance through community businesses 
        and a percentage of every purchase is contributed to your group’s fundraising efforts.`,
    justImagine_textBox1:
        'Just imagine – each time your supporters buy from one of YOUR LOCAL retailers things they are already going',
    justImagine_textBox2:
        'to buy, much needed funds are generated for your group’s fundraising goals.',
    earn_textBox:
        'Earn a minimum of 10% on all purchases from over 200 local merchants in your area.',
    reachOut_textInfoAbout1:
        'Reach out to us today to find out how FunRayZR can help your business,',
    reachOut_textInfoAbout2:
        'charity or non-profit cause raise awareness and keep it local!',
    localBusinessesTitle_textBox: 'About FunRayZR for Local Businesses',
    helpsLocal_textBox1:
        'FunRayZR helps local small businesses market to and connect their business with local consumers who have an interest',
    helpsLocal_textBox2:
        'in supporting and raising money for great local causes including their favourite charities and non-profit groups.',
    helpsLocal_textBox3:
        'FunRayZR is an excellent way for local merchants to establish goodwill and credibility within their community',
    helpsLocal_textBox4:
        'while introducing their business to new consumers.',
    because_localSmall_textBox: 'Local Small Business Love FunRayZR Because:',
    localSmall_textBox1:
        'Much your local small business, FunRayZR is a local community based company that provides merchants with',
    localSmall_textBox2:
        'an ultra-convenient, highly efficient and super-low cost platform to sell, distribute and manage eGift cards for their small business.',
    provides_textBox1:
        'FunRayZR provides everything you need to get your eGift Card program off the ground. Our platform provides',
    provides_textBox2:
        `small businesses to create a profile that will allow business managers to quickly and easily track card balances and create 
        an accessible customer database that can be used for targeted direct marketing campaigns.`,
    justLike_textBox1:
        'Reach out to us today to see how FunRayZR can help promote',
    justLike_textBox2:
        'and grow the profile of your small business with your local community.',
    consumersTitle_textBox: 'About FunRayZR for Local Consumers',
    helpsConsumers_textBox1:
        'FunRayZR helps local consumers support local small businesses while helping raise money for great local causes',
    helpsConsumers_textBox2:
        'including their favourite charities and non-profit groups.',
    everyConsumers_textBox1:
        'Every time you purchase a FunRayZR eGift card for yourself or as a gift for a friend, a portion of the gift card value',
    everyConsumers_textBox2:
        'is immediately donated to a great cause. For example, when you purchase or gift a $25 FunRayZR eGift card,',
    everyConsumers_textBox3:
        'you or your gift recipient will receive $25 worth of credit at the local merchant’s',
    everyConsumers_textBox4:
        'shop, and the local merchant will donate $2.50 to the supported charity or nonprofit. It really is that easy!',
    because_shoppersConsumers_textBox:
        'Local Shoppers Love FunRayZR eGift Cards Because:',
    shoppersConsumers_textBox1:
        'When you purchase or gift a FunRayZR eGift card, you or your gift recipient immediately receive an electronic',
    shoppersConsumers_textBox2:
        'confirmation in your email inbox, and the balance will immediately show up in your FunRayZR app/web profile.',
    usingConsumers_textBox1:
        'Using the Balance on your FunRayZR eGift card is as easy as scanning a QR code at your local merchant.',
    usingConsumers_textBox2:
        'You can use the whole FunRayZR balance at once, or bring the balance down gradually… you have complete control.'
};

const TERMS_OF_USE = {
    termAndCondition: 'TERMS AND CONDITIONS',
    pleaseReadTerm:
        'Please read these terms and conditions carefully before using Our Service.',
    interpretationAndDef: 'Interpretation and Definitions',
    interpretation: 'Interpretation',
    termInterpretation:
        `The words of which the initial letter is capitalized have meanings defined under the following conditions. 
        The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`,
    definitions: 'Definitions',
    purposeDef: 'For the purposes of these Terms and Conditions:',
    appDef: 'Application',
    appDefDetail:
        ' means the software program provided by the Company downloaded by You on any electronic device, named FunRayZR.',
    appStoreDef: 'Application Store',
    appStoreDetail:
        ' means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.',
    affiliateDef: 'Affiliate',
    affiliateDefDetail:
        ` means an entity that controls, is controlled by or is under common control with a party, where "control" 
        means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`,
    accDef: 'Account',
    accDefDetail:
        ' means a unique account created for You to access our Service or parts of our Service.',
    countryDef: 'Country',
    countryDefDetail: ' refers to: Ontario, Canada.',
    companyDef: 'Company',
    companyDefDetail:
        ' (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to FunRayZR Inc., 2899 Cromarty Drive RR#1, Putnam ON, N0L 2B0, Canada.',
    deviceDef: 'Device',
    deviceDefDetail:
        ' means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
    goodsDef: 'Goods',
    goodsDefDetail: ' refer to the items offered for sale on the Service.',
    inAppPurchaseDef: 'In-app Purchase',
    inAppPurchaseDefDetail:
        ` refers to the purchase of a product, item, service or Subscription made through the Application and subject to these 
        Terms and Conditions and/or the Application Store's own terms and conditions.`,
    orderDef: 'Orders',
    orderDefDetail: ' mean a request by You to purchase Goods from Us.',
    serviceDef: 'Service',
    serviceDefDetail: ' refers to the Application or the Website or both.',
    termsAndConditionDef: 'Terms and Conditions',
    termsAndConditionDefDetail:
        ' (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.',
    thirdPartyMediaDef: 'Third-party Social Media Service',
    thirdPartyMediaDefDetail:
        ' means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.',
    websiteDef: 'Website',
    websiteDefDetail: ' refers to FunRayZR, accessible from ',
    websiteLink: 'https://funrayzr.com',
    youDef: 'You',
    youDefDetail:
        ' means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
    acknowledgment: 'Acknowledgment',
    acknowledgmentDesc1:
        `These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. 
        These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.`,
    acknowledgmentDesc2:
        `Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. 
        These Terms and Conditions apply to all visitors, users and others who access or use the Service.`,
    acknowledgmentDesc3:
        'By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.',
    acknowledgmentDesc4:
        'You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.',
    acknowledgmentDesc5:
        `Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of 
        the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal 
        information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. 
        Please read Our Privacy Policy carefully before using Our Service.`,
    placeOrdersOrGoods: 'Placing Orders for Goods',
    placeOrdersOrGoodsDesc:
        'By placing an Order for Goods through the Service, You warrant that You are legally capable of entering into binding contracts.',
    yourInfo: 'Your Information',
    yourInfoDesc1:
        `If You wish to place an Order for Goods available on the Service, You may be asked to supply certain information relevant 
        to Your Order including, without limitation, Your name, Your email, Your phone number, Your credit card number, 
        the expiration date of Your credit card, Your billing address, and Your shipping information.`,
    yourInfoDesc2:
        `You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) 
        in connection with any Order, and that (ii) the information You supply to us is true, correct and complete.`,
    yourInfoDesc3:
        'By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Order.',
    orderCancellation: 'Order Cancellation',
    orderCancellationDesc1:
        'We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited to:',
    orderCancellationDesc1Bullets: {
        bullet1: 'Goods availability',
        bullet2: 'Errors in the description or prices for Goods',
        bullet3: 'Errors in Your Order'
    },
    orderCancellationDesc2:
        'We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected.',
    availabilityErrorInaccuracies: 'Availability, Errors and Inaccuracies',
    availabilityErrorInaccuraciesDesc1:
        `We are constantly updating Our offerings of Goods on the Service. The Goods available on Our Service may be mispriced, 
        described inaccurately, or unavailable, and We may experience delays in updating information regarding our Goods on the Service and in Our advertising on other websites.`,
    availabilityErrorInaccuraciesDesc2:
        `We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, 
        specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.`,
    pricesPolicy: 'Prices Policy',
    pricesPolicyDesc1:
        'The Company reserves the right to revise its prices at any time prior to accepting an Order.',
    pricesPolicyDesc2:
        `The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence affecting 
        delivery caused by government action, variation in customs duties, increased shipping charges, higher foreign exchange costs 
        and any other matter beyond the control of the Company. In that event, You will have the right to cancel Your Order.`,
    paymentsTerm: 'Payments',
    paymentsTermDesc1:
        `All Goods purchased are subject to a one-time payment. Payment can be made through various payment methods we have available, 
        such as Visa, MasterCard, Affinity Card, American Express cards or online payment methods (PayPal, for example).`,
    paymentsTermDesc2:
        `Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer. 
        If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Order.`,
    inAppPurchaseTerm: 'In-app Purchases',
    inAppPurchaseTermDesc1:
        'The Application may include In-app Purchases that allow you to buy products, services or Subscriptions.',
    inAppPurchaseTermDesc2:
        "More information about how you may be able to manage In-app Purchases using your Device may be set out in the Application Store's own terms and conditions or in your Device's Help settings.",
    inAppPurchaseTermDesc3:
        `In-app Purchases can only be consumed within the Application. If you make a In-app Purchase, that In-app Purchase cannot 
        be cancelled after you have initiated its download. In-app Purchases cannot be redeemed for cash or other consideration or otherwise transferred.`,
    inAppPurchaseTermDesc4:
        `If any In-app Purchase is not successfully downloaded or does not work once it has been successfully downloaded, we will, 
        after becoming aware of the fault or being notified to the fault by You, investigate the reason for the fault. We will act 
        reasonably in deciding whether to provide You with a replacement In-app Purchase or issue You with a patch to repair the fault. 
        In no event will We charge You to replace or repair the In-app Purchase. In the unlikely event that we are unable to replace or 
        repair the relevant In-app Purchase or are unable to do so within a reasonable period of time and without significant 
        inconvenience to You, We will authorize the Application Store to refund You an amount up to the cost of the relevant In-app 
        Purchase. Alternatively, if You wish to request a refund, You may do so by contacting the Application Store directly.`,
    inAppPurchaseTermDesc5:
        `You acknowledge and agree that all billing and transaction processes are handled by the Application Store from where 
        you downloaded the Application and are governed by that Application Store's own terms and conditions.`,
    inAppPurchaseTermDesc6:
        'If you have any payment related issues with In-app Purchases, then you need to contact the Application Store directly.',
    userAccount: 'User Accounts',
    userAccountDesc1:
        `When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. 
        Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.`,
    userAccountDesc2:
        `You are responsible for safeguarding the password that You use to access the Service and for any activities or actions 
        under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.`,
    userAccountDesc3:
        'You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.',
    userAccountDesc4:
        `You may not use as a username the name of another person or entity or that is not lawfully available for use, 
        a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.`,
    intellectualProp: 'Intellectual Property',
    intellectualPropDesc1:
        `The Service and its original content (excluding Content provided by You or other users), features and functionality 
        are and will remain the exclusive property of the Company and its licensors.`,
    intellectualPropDesc2:
        'The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.',
    intellectualPropDesc3:
        'Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.',
    linkToOtherWeb: 'Links to Other Websites',
    linkToOtherWebDesc1:
        'Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.',
    linkToOtherWebDesc2:
        `The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any 
        third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, 
        directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or 
        reliance on any such content, goods or services available on or through any such web sites or services.`,
    linkToOtherWebDesc3:
        'We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.',
    termination: 'Termination',
    terminationDesc1:
        'We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.',
    terminationDesc2:
        'Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.',
    limitOfLiability: 'Limitation of Liability',
    limitOfLiabilityDesc1:
        `Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any 
        provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by 
        You through the Service or 100 USD if You haven't purchased anything through the Service.`,
    limitOfLiabilityDesc2:
        `To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, 
        incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, 
            loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or 
            in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used 
            with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has 
            been advised of the possibility of such damages and even if the remedy fails of its essential purpose.`,
    limitOfLiabilityDesc3:
        `Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, 
        which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.`,
    disclaimer: '"AS IS" and "AS AVAILABLE" Disclaimer',
    disclaimerDesc1:
        `The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. 
        To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and 
        its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, 
        statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a 
        particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, 
        usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no 
        representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work 
        with any other software, applications, systems or services, operate without interruption, meet any performance or reliability 
        standards or be error free or that any errors or defects can or will be corrected.`,
    disclaimerDesc2:
        `Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty 
        of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and 
        materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, 
        reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, 
        the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.`,
    disclaimerDesc3:
        `Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory 
        rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case 
        the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.`,
    governingLaw: 'Governing Law',
    governingLawDesc:
        `The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. 
        Your use of the Application may also be subject to other local, state, national, or international laws.`,
    disputesResolution: 'Disputes Resolution',
    disputesResolutionDesc:
        'If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.',
    euUser: 'For European Union (EU) Users',
    euUserDesc:
        'If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.',
    usLegalCompliance: 'United States Legal Compliance',
    usLegalComplianceDesc:
        `You represent and warrant that (i) You are not located in a country that is subject to the United States government 
        embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) 
        You are not listed on any United States government list of prohibited or restricted parties.`,
    severabilityAndWaiver: 'Severability and Waiver',
    severability: 'Severability',
    severabilityDesc:
        `If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to 
        accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.`,
    waiver: 'Waiver',
    waiverDesc:
        `Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms 
        shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.`,
    transInter: 'Translation Interpretation',
    transInterDesc:
        'These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.',
    changeToTermAndCondition: 'Changes to These Terms and Conditions',
    changeToTermAndConditionDesc1:
        `We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material, 
        we will make reasonable efforts to provide at least 30 days; notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.`,
    changeToTermAndConditionDesc2:
        `By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. 
        If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.`,
    contactUsTerm: 'Contact Us',
    contactUsTermDesc:
        'If you have any questions about these Terms and Conditions, You can contact us:',
    contactUsTermDescBullets: {
        bullet1: 'By email: info@funrayzr.com',
        bullet2: 'By phone number: (226) 376-4911'
    }
};

const EULA = {
    title: 'END-USER LICENSE AGREEMENT ("AGREEMENT")',
    interpretationAndDef: 'Interpretation and Definitions',
    interpretation: 'Interpretation',
    termInterpretation:
        `The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions 
        shall have the same meaning regardless of whether they appear in singular or in plural.`,
    definitions: 'Definitions',
    definitionsDesc: 'For the purposes of this End-User License Agreement:',
    defDescBullet: {
        bullet1: {
            subject: 'Agreement',
            desc: ' means this End-User License Agreement that forms the entire agreement between You and the Company regarding the use of the Application.'
        },
        bullet2: {
            subject: 'Application',
            desc: " means the software program provided by the Company downloaded by You through an Application Store's account to a Device, named FunRayZR."
        },
        bullet3: {
            subject: 'Application Store',
            desc: ` means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. 
            (Google Play Store) by which the Application has been downloaded to your Device.`
        },
        bullet4: {
            subject: 'Company',
            desc: ' (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to FunRayZR Inc., 2899 Cromarty Drive RR#1, Putnam ON, N0L 2B0, Canada.'
        },
        bullet5: {
            subject: 'Content',
            desc: ' refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.'
        },
        bullet6: {
            subject: 'Country',
            desc: ' refers to: Ontario, Canada.'
        },
        bullet7: {
            subject: 'Device',
            desc: ' means any device that can access the Application such as a computer, a cellphone or a digital tablet.'
        },
        bullet8: {
            subject: 'Family Sharing / Family Group',
            desc: ` permits You to share applications downloaded through the Application Store with other family members by 
            allowing them to view and download each others' eligible Applications to their associated Devices.`
        },
        bullet9: {
            subject: 'Third-Party Services',
            desc: ` means any services or content (including data, information, applications and other products services) 
            provided by a third-party that may be displayed, included or made available by the Application.`
        },
        bullet10: {
            subject: 'You',
            desc: ` means the individual accessing or using the Application or the company, or other legal entity on behalf of 
            which such individual is accessing or using the Application, as applicable.`
        }
    },
    acknowledgment: 'Acknowledgment',
    acknowledgmentDesc1:
        `By clicking the "I Agree" button, downloading or using the Application, You are agreeing to be bound by the terms and 
        conditions of this Agreement. If You do not agree to the terms of this Agreement, do not click on the "I Agree" button, do not download or do not use the Application.`,
    acknowledgmentDesc2:
        'This Agreement is a legal document between You and the Company and it governs your use of the Application made available to You by the Company.',
    acknowledgmentDesc3:
        `This Agreement is between You and the Company only and not with the Application Store. Therefore, the Company is solely 
        responsible for the Application and its content. Although the Application Store is not a party to this Agreement, it has the 
        right to enforce it against You as a third party beneficiary relating to your use of the Application.`,
    acknowledgmentDesc4:
        `Since the Application can be accessed and used by other users via, for example, Family Sharing / Family Group or volume 
        purchasing, the use of the Application by those users is expressly subject to this Agreement.`,
    acknowledgmentDesc5:
        'The Application is licensed, not sold, to You by the Company for use strictly in accordance with the terms of this Agreement.',
    license: 'License',
    scopeOfLicense: 'Scope of License',
    scopeOfLicenseDesc1:
        'The Company grants You a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application strictly in accordance with the terms of this Agreement.',
    scopeOfLicenseDesc2:
        "You may only use the Application on a Device that You own or control and as permitted by the Application Store's terms and conditions.",
    scopeOfLicenseDesc3:
        'The license that is granted to You by the Company is solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.',
    intellectualProp: 'Intellectual Property',
    intellectualPropDesc1:
        `The Application, including without limitation all copyrights, patents, trademarks, trade secrets and other intellectual 
        property rights are, and shall remain, the sole and exclusive property of the Company.`,
    intellectualPropDesc2:
        `The Company shall not be obligated to indemnify or defend You with respect to any third party claim arising out of or relating 
        to the Application. To the extent the Company is required to provide indemnification by applicable law, the Company, 
        not the Application Store, shall be solely responsible for the investigation, defense, settlement and discharge of any 
        claim that the Application or your use of it infringes any third party intellectual property rights.`,
    thirdPartyService: 'Third-Party Services',
    thirdPartyServiceDesc1:
        `The Application may display, include or make available third-party content (including data, information, 
            applications and other products services) or provide links to third-party websites or services.`,
    thirdPartyServiceDesc2:
        `You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, 
        completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. 
        The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.`,
    thirdPartyServiceDesc3:
        `You must comply with applicable Third parties' Terms of agreement when using the Application. Third-party Services 
        and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and 
        subject to such third parties' Terms and conditions.`,
    privacyPolicy: 'Privacy Policy',
    privacyPolicyDesc1: {
        desc: 'The Company collects, stores, maintains, and shares information about You in accordance with Our Privacy Policy: ',
        link: 'https://funrayzr.com/privacy-policy'
    },
    privacyPolicyDesc2:
        'By accepting this Agreement, You acknowledge that You hereby agree and consent to the terms and conditions of Our Privacy Policy.',
    termAndTermination: 'Term and Termination',
    termAndTerminationDesc1:
        `This Agreement shall remain in effect until terminated by You or the Company. The Company may, in its sole discretion, 
        at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.`,
    termAndTerminationDesc2:
        `This Agreement will terminate immediately, without prior notice from the Company, in the event that you fail to comply 
        with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your Device or from your computer.`,
    termAndTerminationDesc3:
        'Upon termination of this Agreement, You shall cease all use of the Application and delete all copies of the Application from your Device.',
    termAndTerminationDesc4:
        `Termination of this Agreement will not limit any of the Company's rights or remedies at law or in equity in case of breach 
        by You (during the term of this Agreement) of any of your obligations under the present Agreement.`,
    indemnification: 'Indemnification',
    indemnificationDesc:
        `You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, 
        partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising 
        out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.`,
    noWarranties: 'No Warranties',
    noWarrantiesDesc1:
        `The Application is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. 
        To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and 
        its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, 
        statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a 
        particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, 
        usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no 
        representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or 
        work with any other software, applications, systems or services, operate without interruption, meet any performance or 
        reliability standards or be error free or that any errors or defects can or will be corrected.`,
    noWarrantiesDesc2:
        `Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty 
        of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, 
        and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the 
        accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, 
        its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.`,
    noWarrantiesDesc3:
        `Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights 
        of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions 
        and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. 
        To the extent any warranty exists under law that cannot be disclaimed, the Company, not the Application Store, shall be solely responsible for such warranty.`,
    limitOfLiability: 'Limitation of Liability',
    limitOfLiabilityDesc1:
        `Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under 
        any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually 
        paid by You for the Application or through the Application or 100 USD if You haven't purchased anything through the Application.`,
    limitOfLiabilityDesc2:
        `To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, 
        incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, 
            loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or 
            in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware 
            used with the Application, or otherwise in connection with any provision of this Agreement), even if the Company or any 
            supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.`,
    limitOfLiabilityDesc3:
        'Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to You',
    limitOfLiabilityDesc4:
        `You expressly understand and agree that the Application Store, its subsidiaries and affiliates, and its licensors shall 
        not be liable to You under any theory of liability for any direct, indirect, incidental, special consequential or exemplary 
        damages that may be incurred by You, including any loss of data, whether or not the Application Store or its representatives 
        have been advised of or should have been aware of the possibility of any such losses arising.`,
    severabilityAndWaiver: 'Severability and Waiver',
    severability: 'Severability',
    severabilityDesc:
        `If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to 
        accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.`,
    waiver: 'Waiver',
    waiverDesc:
        `Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement 
        shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.`,
    productClaim: 'Product Claims',
    productClaimDesc:
        `The Company does not make any warranties concerning the Application. To the extent You have any claim arising from or relating 
        to your use of the Application, the Company, not the Application Store, is responsible for addressing any such claims, 
        which may include, but not limited to: (i) any product liability claims; (ii) any claim that the Application fails to 
        conform to any applicable legal or regulatory requirement; and (iii) any claim arising under consumer protection, or similar legislation.`,
    usLegalCompliance: 'United States Legal Compliance',
    usLegalComplianceDesc:
        `You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, 
        or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on 
        any United States government list of prohibited or restricted parties.`,
    changeToAgreement: 'Changes to this Agreement',
    changeToAgreementDesc1:
        `The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is 
        material, we will provide at least 30 days; notice prior to any new terms taking effect. What constitutes a material change will be determined at the sole discretion of the Company.`,
    changeToAgreementDesc2:
        `By continuing to access or use the Application after any revisions become effective, You agree to be bound by the revised terms. 
        If You do not agree to the new terms, You are no longer authorized to use the Application.`,
    governingLaw: 'Governing Law',
    governingLawDesc:
        `The laws of the Country, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. 
        Your use of the Application may also be subject to other local, state, national, or international laws.`,
    entireAgreement: 'Entire Agreement',
    entireAgreementDesc1:
        `The Agreement constitutes the entire agreement between You and the Company regarding your use of the Application and supersedes 
        all prior and contemporaneous written or oral agreements between You and the Company.`,
    entireAgreementDesc2:
        `You may be subject to additional terms and conditions that apply when You use or purchase other Company's services, 
        which the Company will provide to You at the time of such use or purchase.`,
    contactUs: 'Contact Us',
    contactUsDesc:
        'If you have any questions about this Agreement, You can contact Us:',
    contactUsDescBullets: {
        bullet1: 'By email: info@funrayzr.com',
        bullet2: 'By phone number: (226) 376-4911'
    }
};

const SHIPPING_POLICY = {
    title: 'SHIPPING POLICY',
    titleDesc: 'FunRayZR Shipping Policy and Product Description',
    titleDescContent: {
        p1: 'FunRayZR digital gift certificates never expire.',
        p2: 'FunRayZR digital gift certificates are not transferable. They should be used by the purchaser or the person they were purchased for.',
        p3: 'FunRayZR digital gift cards are delivered by email, usually within 1 hour of purchase.',
        p4: 'FunRayZR digital gift cards have no cash value, they can be redeemed for goods or services offered by the specific vendor.',
        p5: 'FunRayZR gift cards are redeemed by displaying your unique QR code to the vendor at time of purchase. The QR code can be displayed on your mobile device or printed out.',
        p6: 'There are no additional taxes or delivery charges when you purchase a FunRayZR digital gift card, the card can be used to purchase taxable goods and services.'
    }
};

export { REFUND_POLICY, PRIVACY_POLICY, FAQ_POPUP, ABOUT_RAYZR, ABOUT_FUNRAYZR, TERMS_OF_USE, EULA, SHIPPING_POLICY };
