import CharityDrives from '.';
import { CHARITY_DRIVES_URL } from './url';

export const CHARITY_DRIVES_ROUTES = [
  {
    name: CHARITY_DRIVES_URL.CHARITY_DRIVES.NAME,
    path: CHARITY_DRIVES_URL.CHARITY_DRIVES.URL,
    component: CharityDrives,
    isPrivate: false
  }
];
