import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { REGISTER_URL } from 'pages/register/url';
import { Link } from 'react-router-dom';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';
import { IMG_LOCAL, BUCKET, APP_KEY } from 'constants/index';

const localBusiness = () => {

  return (
      <Container>
          <Row className="py-4">
            <Col md="6">
              <em className="greenem">10% GETS DONATED TO CHARITY</em>
              <h2>Grow your business with gift cards</h2>
              <h3>Partner with local charities who will promote your business to their members.</h3>

              <Link
                to={SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL}
                className="button-style primary"
              >
                How it works
              </Link>
              <Link
                to={REGISTER_URL.REGISTER.URL}
                className="button-style"
              >
                Sign Me Up
              </Link>
            </Col>
            <Col md="6">
              <img className="w-100" src={IMG_LOCAL.SHOPPING_PAY_TAP} />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h3>Attract new customers</h3>
              <p>Stay connected with your team and make quick decisions wherever you are.</p>
            </Col>
            <Col md="4">
              <h3>Give to local charities</h3>
              <p>10% of all purchases goes to organizations you want to support</p>
            </Col>
            <Col md="4">
              <h3>Sell gift cards</h3>
              <p>Promote your business with gift cards.</p>
            </Col>
          </Row>
      </Container>
  );
};

export default localBusiness;
