import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import { IMG_LOCAL } from 'constants/index';

const useStyles = makeStyles(() => ({
  greenem: {
    background: 'rgba(234,250,240,1)',
    fontSize: '0.76rem',
    fontWeight: '500',
    fontStyle: 'initial',
    textTransform: 'uppercase',
    padding: '.38em .62em',
    borderRadius: '1em',
    color: 'rgba(42,209,103,1)'
  },
  bgGreen: {
    background: 'url(' + IMG_LOCAL.WAVE_BG + ') no-repeat center, url(' + IMG_LOCAL.DOTS_RED + ') no-repeat 1rem 1rem, url(' + IMG_LOCAL.DOTS_RED + ') no-repeat calc(100% - 1rem) calc(100% - 1rem)',
    backgroundColor: 'rgba(42,209,103,1)',
    borderRadius: '.38rem',
    color: 'white',
    padding: '2.62rem 2.62rem'
  },
  heroContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'calc(100vh - 60px)',
    '& .logo': {
      maxWidth: '300px',
      '@media (max-width: 600px)': {
        maxWidth: '100px'
      }
    }
  },
  heroTitle: {
    fontSize: '60px',
    marginBottom: '2rem',
    fontWeight: '900'
  },
  heroText: {
    fontSize: theme.fontSize.s22,
    textAlign: 'center',
    marginBottom: '1.5rem',
    padding: '0 15px',
    '@media (max-width: 600px)': {
      fontSize: theme.fontSize.s13
    }
  },
  heroButton: {
    '&.rayzr': {
      position: 'absolute',
      top: '45px',
      left: '70px'
    },
    '@media (max-width: 768px)': {
      '&.rayzr': {
        top: '45px',
        left: '45px'
      }
    },
    '@media (max-width: 600px)': {
      padding: '10px 15px !important',
      '&.rayzr': {
        top: '22px',
        left: '25px',
        fontSize: `${theme.fontSize.s13} !important`
      }
    }
  },
  authenticationArea: {
    marginTop: '3rem',
    marginBottom: '3rem'
  },
  textArea: {
    '& p': {
      color: theme.colors.white.text,
      textAlign: 'center',
      marginBottom: 0
    }
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 auto',
    maxWidth: '550px',
    '@media (max-width: 768px)': {
      maxWidth: '375px'
    }
  },
  learnMore: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s20,
    fontFamily: theme.fontFamily.titanOneRegular,
    textShadow: `0px 3px 7px ${theme.text.textShadowColor}`,
    '&:hover': {
      color: theme.colors.white.text,
      textDecoration: 'none'
    }
  },
  actionBlock: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '20px 10px',
    boxShadow: '0px 10px 20px #00000029',
    maxHeight: '288px',
    marginBottom: '10px',
    '&.charity': {
      background: theme.colors.darkcyan.background
    },
    '&.vendor': {
      background: theme.colors.orange.background
    },
    '& .support': {
      position: 'absolute',
      fontFamily: theme.fontFamily.titanOneRegular,
      color: theme.colors.white.title,
      background: theme.colors.violet.background,
      width: '100px',
      height: '200px',
      top: '-75px',
      left: '-25px',
      transform: 'rotate(45deg)',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      '& .text': {
        transform: 'rotate(-90deg)',
        paddingTop: '40px',
        fontSize: '20px',
        textShadow: `0px 3px 7px ${theme.colors.black.background}`
      }
    },
    '& h3': {
      color: theme.colors.white.title,
      fontFamily: theme.fontFamily.titanOneRegular,
      textShadow: `0px 3px 7px ${theme.colors.black.background}`
    },
    '& p': {
      color: theme.colors.white.title,
      fontFamily: theme.fontFamily.quicksandLight,
      marginBottom: 0
    },
    '& a': {
      fontFamily: `${theme.fontFamily.titanOneRegular}!important`,
      padding: '10px 25px !important',
      maxWidth: '130px',
      boxShadow: `0px 3px 7px ${theme.text.textShadowColor}`
    }
  },
  logoBlock: {

  },
  boxShadow: {
    boxShadow: `0px 3px 7px ${theme.text.textShadowColor}`,
    borderRadius: '8px'
  },
  eGiftCard: {
    background: theme.colors.orange.background,
    borderRadius: '9px',
    marginTop: '-8%',
    padding: '40px 0',
    '& .egift-card-title, .egift-card-content': {
      textAlign: 'center',
      color: theme.colors.white.text
    },
    '& .egift-card-title': {
      fontFamily: theme.fontFamily.titanOneRegular,
      textShadow: `0px 3px 7px ${theme.colors.black.text}`,
      marginBottom: '20px'
    },
    '& .egift-card-content': {
      marginBottom: '10px',
      padding: '0 18%',
      textAlign: 'left',
      lineHeight: '1.5'
    }
  }
}));

export default useStyles;
