import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { TRANSACTION_URL } from 'pages/transaction/url';
import urlHelper from 'utils/url';
import { REGISTER_URL } from 'pages/register/url';
import { SIGNIN_URL } from 'pages/signIn/url';
import authService from 'services/authorization';
import { SIGNEDOUT_URL } from 'pages/signedOut/url';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { USER_ROLE } from 'constants/index';
import { ABOUT_URL } from 'pages/about/url';
import { SCAN_VENDOR_TRANSACTIONS_URL } from 'pages/scanVendorTransactions/url';
import { LayoutContext } from 'layout/layoutContext';
import { isMobileByWatchMedia } from 'utils/helper';

const LoginMenu = (props) => {
  const isCancelled = useRef(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isVendorEmployee, setIsVendorEmployee] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const { dispatch } = useContext(LayoutContext);

  const { isAdminPortal, locationPath, onMenuClick } = props;
  const history = useHistory();
  const isMobile = isMobileByWatchMedia();

  const onMenuClickHandle = useCallback(() => {
    if (onMenuClick) {
      onMenuClick();
    }
  }, [onMenuClick]);

  useEffect(() => {
    const populateState = async () => {
      const user = authService.getDecryptedUser();
      const canViewAdmin = authService.isSuperAdministrator() || authService.isAdministrator() || authService.isManager();
      const canViewVendorEmployee = authService.isEmployee() && authService.isVendor();
      authService.subscribeAccountStatus();
      if (isCancelled.current) return;
      setIsAdministrator(canViewAdmin);
      setIsVendorEmployee(canViewVendorEmployee);
      if (user?.displayName) {
        setIsAuthenticated(true);
        setUserInfo(user);
      } else {
        setIsAuthenticated(false);
        setUserInfo(null);
      }
    };

    const subscription = authService.subscribe(() => populateState());

    populateState();

    return () => {
      isCancelled.current = true;
      if (subscription) {
        authService.unsubscribe(subscription);
      }
    };
  }, []);

  const handleBackToSignIn = useCallback(
    (path) => {
      if (urlHelper.isSignIn(path) && authService.isAuthenticated()) {
        const isAdmin = authService.isSuperAdministrator() || authService.isAdministrator();
        const isManager = authService.isManager();
        if (isAdmin || isManager) {
          history.push(ADMIN_PORTAL_URL.USER.URL);
        } else {
          history.push(ABOUT_URL.ABOUT.URL);
        }
      }
    },
    [history]
  );

  useEffect(() => {
    const { pathname } = window.location;
    handleBackToSignIn(pathname);
    history.listen(async (location) => {
      handleBackToSignIn(location.pathname);
    });
  }, [handleBackToSignIn, history]);

  const onSignOut = useCallback(() => {
    dispatch({ type: 'cartTotalCount', payload: 0 });
    authService.signOut();
    if (onMenuClick) {
      onMenuClick();
    }
    history.push(SIGNEDOUT_URL.SIGNEDOUT.URL);
  }, [history, onMenuClick, dispatch]);

  const signOutView = useMemo(() => {
    return (
      <NavItem className="fr-nav-item">
        <NavLink className="fr-nav-link orange" onClick={onSignOut}>
          {SIGNEDOUT_URL.SIGNEDOUT.NAME}
        </NavLink>
      </NavItem>
    );
  }, [onSignOut]);

  const vendorEmployeeView = useMemo(
    () => (
      <NavItem className="fr-nav-item">
        <NavLink
          tag={Link}
          className={`fr-nav-link ${urlHelper.getUrlStatus(
            SCAN_VENDOR_TRANSACTIONS_URL.LIST.URL,
            locationPath
          )}`}
          to={SCAN_VENDOR_TRANSACTIONS_URL.LIST.URL}
          onClick={onMenuClickHandle}
        >
          {SCAN_VENDOR_TRANSACTIONS_URL.LIST.NAME}
        </NavLink>
      </NavItem>
    ),
    [locationPath, onMenuClickHandle]
  );

  const authenticatedView = useMemo(() => {
    if (isAdminPortal) {
      const user = userInfo || {};
      const { avatarText, displayName, roles } = user;
      const roleNames = (roles || []).filter((value) => {
        return value !== USER_ROLE.CONSUMER;
      });
      const roleView = (roleNames || []).join(', ');
      return (
        <ul className="navbar-nav fr-nav fr-admin-nav">
          <NavItem className="fr-nav-item profile-info">
            <div className="avatar">{avatarText}</div>
            <div className="profile">
              <div className="name" title={displayName}>
                {displayName}
              </div>
              <div className="role" title={roleView}>
                {roleView}
              </div>
            </div>
          </NavItem>
          {signOutView}
        </ul>
      );
    }

    return (
      <>
        <NavItem className="fr-nav-item">
          <NavLink
            tag={Link}
            className={`fr-nav-link ${urlHelper.getUrlStatus(
              TRANSACTION_URL.TRANSACTION.URL,
              locationPath
            )}`}
            to={TRANSACTION_URL.TRANSACTION.URL}
            onClick={onMenuClickHandle}
          >
            {TRANSACTION_URL.TRANSACTION.NAME}
          </NavLink>
        </NavItem>
        {isVendorEmployee && vendorEmployeeView}
        {isAdministrator && !isMobile}
        {signOutView}
      </>
    );
  }, [
    vendorEmployeeView,
    isAdminPortal,
    isAdministrator,
    isVendorEmployee,
    locationPath,
    onMenuClickHandle,
    signOutView,
    userInfo,
    isMobile
  ]);

  const signInView = useMemo(() => {
    const loginPath = SIGNIN_URL.SIGNIN.URL;
    return (
      <NavItem className="fr-nav-item">
        <NavLink
          tag={Link}
          className={`fr-nav-link ${urlHelper.getUrlStatus(
            SIGNIN_URL.SIGNIN.URL,
            locationPath
          )}`}
          to={loginPath}
          onClick={onMenuClickHandle}
        >
          {SIGNIN_URL.SIGNIN.NAME}
        </NavLink>
      </NavItem>
    );
  }, [locationPath, onMenuClickHandle]);

  const anonymousView = useMemo(() => {
    const registerPath = `${REGISTER_URL.REGISTER.URL}`;

    if (isAdminPortal) {
      return null;
    }

    return (
      <>
        {signInView}
        <NavItem className="fr-nav-item">
          <NavLink
            tag={Link}
            className={`fr-nav-link primary-nav ${urlHelper.getUrlStatus(
              REGISTER_URL.REGISTER.URL,
              locationPath
            )}`}
            to={registerPath}
            onClick={onMenuClickHandle}
          >
            Sign Up
          </NavLink>
        </NavItem>
        {!isMobile}
      </>
    );
  }, [isAdminPortal, isMobile, locationPath, onMenuClickHandle, signInView]);

  const renderView = useMemo(() => {
    if (!isAuthenticated) {
      return anonymousView;
    }
    return authenticatedView;
  }, [anonymousView, authenticatedView, isAuthenticated]);

  return <>{renderView}</>;
};

export default LoginMenu;
