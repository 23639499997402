import LocalBusiness from '.';
import { LOCAL_BUSINESS_URL } from './url';

export const LOCAL_BUSINESS_ROUTES = [
  {
    name: LOCAL_BUSINESS_URL.LOCAL_BUSINESS.NAME,
    path: LOCAL_BUSINESS_URL.LOCAL_BUSINESS.URL,
    component: LocalBusiness,
    isPrivate: false
  }
];
