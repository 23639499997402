import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import { IMG_LOCAL } from 'constants/index';

const useStyles = makeStyles(() => ({
  greenem: {
    background: 'rgba(234,250,240,1)',
    fontSize: '0.76rem',
    fontWeight: '500',
    fontStyle: 'initial',
    textTransform: 'uppercase',
    padding: '.38em .62em',
    borderRadius: '1em',
    color: 'rgba(42,209,103,1)'
  },
  bgGreen: {
    background: 'url(' + IMG_LOCAL.WAVE_BG + ') no-repeat center, url(' + IMG_LOCAL.DOTS_RED + ') no-repeat 1rem 1rem, url(' + IMG_LOCAL.DOTS_RED + ') no-repeat calc(100% - 1rem) calc(100% - 1rem)',
    backgroundColor: 'rgba(42,209,103,1)',
    borderRadius: '.38rem',
    color: 'white',
    padding: '2.62rem 2.62rem'
  }
}));

export default useStyles;
