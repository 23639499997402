import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { REGISTER_URL } from 'pages/register/url';
import { Link } from 'react-router-dom';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';
import useStyles from './styles';

const CharityDrives = () => {

  const {
    bgGreen
  } = useStyles();

  return (
      <Container>
          <Row className="py-4 text-center">
            <Col md="12">
              <em className="greenem">BUSINESSES DONATE 10% OF THE VALUE OF THE GIFT CARDS YOUR EMPLOYEES BUY</em>
              <h2>Charity drives your employees will love</h2>
              <h3>Find out why gift cards are a painless way to run a charity drive</h3>

              <Link
                to={SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL}
                className="button-style primary"
              >
                Request a Demo
              </Link>
              <Link
                to={REGISTER_URL.REGISTER.URL}
                className="button-style"
              >
                Sign Up
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h3>Free Campaign Page</h3>
              <p>Set up a campaign in minutes</p>
            </Col>
            <Col md="4">
              <h3>Mail your staff & clients</h3>
              <p>Link them to your new campaign page</p>
            </Col>
            <Col md="4">
              <h3>Let them choose</h3>
              <p>Businesses they already frequent</p>
            </Col>
          </Row>
          <Row className={bgGreen}>
            <Col md="8">
              <h2>Get started today</h2>
              <p>You&rsquo;ll have a campaign up and running in no time.</p>
            </Col>
            <Col md="4 text-right">
              <Link to={SHOP_VENDOR_URL.SHOP_VENDOR.URL} className="button-style">
                Sign up for free
              </Link>
            </Col>
          </Row>
      </Container>
  );
};

export default CharityDrives;
