import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { makeStyles } from '@mui/styles';

import { theme } from 'constants/theme';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '50px 0px 20px 0px'
  }
}));
const ContainerFooterLink = ({ children, title }) => {
  const { border } = useStyles();

  return (
    <div className="fr-bg-admin pb-md-5">
      <h1 className="fr-page-title text-uppercase" id="headePage">
        {title}
      </h1>
      <Container className="bg-white rounded">
        <Row>
          <Col xs={12}>
            <div className={border} />
          </Col>
          <Col xs={12}>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContainerFooterLink;
